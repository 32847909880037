import React, { 
  useState, 
  useEffect, 
  useRef 
} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Container,
  Paper,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  IconButton,
  CircularProgress
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "react-datepicker/dist/react-datepicker.css";
import AddServiceModal from "../components/AddServiceModal";
import DateTimeModal from "../components/DateTimeModal"
import JobCompletedFormModal from "../components/JobCompletedFormModal";
import { 
  toastify,
  addTaxToPrice,
  subtractTaxFromPrice,
  uploadImage,
  addHoursToDate,
  addEventToCalendar,
  updateCalendarEventById,
  deleteEventById
} from "../utils";
import ServiceCard from "../components/ServiceCard";
import AppointmentNotes from "../components/AppointmentNotes";
import {
  getAppointment,
  updateAppointment
} from "../api/appointments";
import { getClient } from "../api/clients";
import { 
  getProviders, 
  checkIfDateTimeOverlapsForProvider
} from "../api/providers";

const Appointment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [appointment, setAppointment] = useState(null);
  const [client, setClient] = useState(null);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openServicesModal, setOpenServicesModal] = useState(false);
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const priceTextFieldRef = useRef(null);
  const [priceEditEnable, setPriceEditEnable] = useState(false);
  const [jobCompletedFormModalOpen, setJobCompletedFormModalOpen] = useState(false);
  const statusOptions = [
    "Pending",
    "Pending Custom",
    "Quote Requested",
    "Quote Sent",
    "Quote Approved",
    "Deposit Paid",
    "Confirmed",
    "Started",
    "Completed",
    "Partial",
    "Paid",
    "Cancelled",
  ];
  const [loading, setLoading] = useState(false);

  const handleOpenServicesModal = () => setOpenServicesModal(true);
  const handleCloseServicesModal = () => setOpenServicesModal(false);

  const handleOpenDateTimeModal = () => setOpenDateTimeModal(true);
  const handleCloseDateTimeModal = () => setOpenDateTimeModal(false);

  const fetchAppointment = async () => {
    try {
      const response = await getAppointment(id);
      setAppointment(response);
    } catch (error) {
      console.error("There was an error fetching appointment:", error);
    }
  };

  const fetchClient = async (clientId) => {
    try {
      const response = await getClient(clientId);
      setClient(response);
    } catch (error) {
      console.error("There was an error fetching client:", error);
    }
  };

  const fetchProviders = async () => {
    try {
      const providers = await getProviders();
      setProviders(providers);
      if (appointment && appointment.providerId) {
        const provider = providers.find((provider) => provider._id === appointment.providerId);
        setSelectedProvider(provider);
      }
    } catch (error) {
      console.error("There was an error fetching providers:", error);
    }
  };

  useEffect(() => {
    fetchAppointment();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (appointment && appointment.clientId) {
      fetchClient(appointment.clientId);
    }
    fetchProviders();
    // eslint-disable-next-line
  }, [appointment]);

  const formatDateTime = (dateTime) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    }).format(new Date(dateTime));
  };

  const handleAppointmentChange = (property, value) => {
    setAppointment((currentAppointment) => {
      return {
        ...currentAppointment,
        [property]: value,
      };
    });
  };

  const handleProviderChange = async (providerId) => {
    const timeOverlaps = await checkIfDateTimeOverlapsForProvider(
      appointment._id,
      appointment.dateTime, 
      appointment.duration, 
      providerId
    );
    if (timeOverlaps) {
      toastify('Handyman unavailable at this time', 'error');
    } else {
      handleAppointmentChange('providerId', providerId);
    }
  };

  const handleAddressChange = (value, index) => {
    let addressParts = appointment.address.split(',');
    if (index === 3) {
      const unitPrefix = "Unit ";
      if (addressParts.length > index) {
        addressParts[index] = `${unitPrefix}${value}`;
      } else {
        addressParts.push(`${unitPrefix}${value}`);
      }
    } else {
      if (addressParts.length > index) {
        addressParts[index] = value
      }
    }
    const newAddress = addressParts.join(',');
    handleAppointmentChange('address', newAddress);
  };

  const handleDeleteService = (service) => {
    const index = appointment.services.findIndex(s => s._id === service._id);
    if (index > -1) { 
      const updatedServices = [...appointment.services];
      updatedServices.splice(index, 1);
      const updatedDuration = appointment.duration - service.baseDuration;
      const updatedPrice = appointment.price - addTaxToPrice(service.basePrice, 13);
      
      setAppointment((currentAppointment) => ({
        ...currentAppointment,
        services: updatedServices,
        duration: updatedDuration,
        price: updatedPrice,
      }));
    }
  };  
  
  const handleAddService = (service) => {
    const updatedServices = [...appointment.services, service];
    const updatedDuration = parseFloat(appointment.duration) + parseFloat(service.baseDuration);
    const updatedPrice = parseFloat(appointment.price) + parseFloat(addTaxToPrice(service.basePrice, 13));
    
    setAppointment((currentAppointment) => ({
      ...currentAppointment,
      services: updatedServices,
      duration: updatedDuration,
      price: updatedPrice,
    }));
    handleCloseServicesModal();
  };

  const enableEditPrice = () => {
    setPriceEditEnable(true);
    handleAppointmentChange("price", subtractTaxFromPrice(appointment.price, 13));
  };

  useEffect(() => {
    if (priceEditEnable) {
      priceTextFieldRef.current.focus();
    }
  }, [priceEditEnable]);

  const disablePriceChange = () => {
    setPriceEditEnable(false);
    handleAppointmentChange("price", addTaxToPrice(appointment.price, 13));
  };

  const handlePriceChange = (value) => {
    handleAppointmentChange("price", value)
  };

  const handleStatusChange = (value) => {
    if (value === "Completed") {
      setJobCompletedFormModalOpen(true);
    } else {
      handleAppointmentChange("status", value)
    }
  }

  const handleCompletedJobFormSubmit = (FormData) => {
    handleAppointmentChange("notes", FormData);
    handleAppointmentChange("status", "Completed");
    setJobCompletedFormModalOpen(false);
  };

  const handleCalendarEventForHandyman = async () => {
    const handymanEmail = selectedProvider.email;
    if (appointment.status === "Confirmed") {
      const event = {
        'summary': `${client.firstName} ${client.lastName}`,
        'location': `${appointment.address}`,
        'description': `Client Phone: ${client.phone}`,
        'start': {
          'dateTime': appointment.dateTime,
          'timeZone': 'America/Toronto',
        },
        'end': {
          'dateTime': addHoursToDate(appointment.dateTime, appointment.duration),
          'timeZone': 'America/Toronto',
        },
        'reminders': {
          'useDefault': false,
          'overrides': [
            {'method': 'email', 'minutes': 24 * 60},
            {'method': 'popup', 'minutes': 10},
          ],
        },
      };
      if (!appointment.eventId) {
        const eventId = await addEventToCalendar(handymanEmail, event);
        appointment.eventId = eventId;
        setAppointment(appointment);
      } else {
        updateCalendarEventById(handymanEmail, appointment.eventId, event);
      }
    } 
    else if (appointment.status === "Cancelled" && appointment.eventId) {
      deleteEventById(handymanEmail, appointment.eventId);
    }
  };

  const handleUpdateServiceImages = (serviceId, newImages) => {
    appointment.services.find(service => service._id === serviceId).images = newImages;
    setAppointment(appointment);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { providerId } = appointment;
    if (providerId === null) {
      toastify("Handyman must be assigned to appointment", "error");
    } else {
      try {
        for(const service of appointment.services) {
          const afterImagesToBeAdded = service.images.filter(item => item.type === "After" && "file" in item);
          if (afterImagesToBeAdded.length > 0) {
            for (const image of afterImagesToBeAdded) {
              const url = await uploadImage(image.file);
              image.url = url;
              delete image.file;
            }
          }
        }
        await handleCalendarEventForHandyman();
        await updateAppointment(appointment, id);
        toastify("Appointment Updated!", "success");
        navigate(`/appointments`);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    setLoading(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {
        loading ?
        <Box
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh' 
          }}
        >
          <CircularProgress size={100} />
        </Box> :
        <Container>
          <Box my={6}>
            <Typography 
              variant="h4" 
              component="h1" 
              gutterBottom 
              align="center" 
              color="primary">
              Appointment Info
            </Typography>
          </Box>
          {appointment ? (
            <Paper 
              elevation={6} 
              sx={{ p: 4, my: 2 }}>
              <Box 
                display="flex" 
                justifyContent="flex-end" 
                mb={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
              <Typography 
                variant="h5" 
                gutterBottom>
                {`Appointment ID: ${appointment.UUID}`}
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom>
                {`Client Name: ${
                  client ? `${client.firstName} ${client.lastName}` : ""
                }`}
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom>
                {`Client Email: ${client ? `${client.email}` : ""}`}
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom>
                {`Client Phone: ${client ? `${client.phone}` : ""}`}
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom>
                {`Created On: ${formatDateTime(appointment.created)}`}
              </Typography>
              <Box 
                display="flex" 
                my={2}>
                <Typography 
                  variant="body1" 
                  gutterBottom>
                  Date of Appointment: {formatDateTime(appointment.dateTime)}
                </Typography>
                <IconButton onClick={handleOpenDateTimeModal}>
                  <EditIcon />
                </IconButton>
              </Box>
              {['Address', 'City', 'Postal Code', 'Unit Number'].map((label, index) => (
                <Box 
                  key={label} 
                  my={2} 
                  display="flex" 
                  alignItems="center">
                  <Typography 
                    variant="body1" 
                    gutterBottom 
                    sx={{ width: '120px' }}>
                    {label}:
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={appointment.address.split(',')[index]?.replace("Unit ", "").trim() ?? ''}
                    onChange={(e) => handleAddressChange(e.target.value, index)}
                  />
                </Box>
              ))}
              <Box 
                my={2} 
                display="flex" 
                alignItems="center">
                <Typography 
                  variant="body1" 
                  gutterBottom 
                  sx={{ width: '120px' }}>
                  Duration:
                </Typography>
                <TextField
                  variant="outlined"
                  value={appointment.duration}
                  onChange={(e) => handleAppointmentChange("duration", e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        hours
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box 
                my={2} 
                display="flex" 
                alignItems="center">
                <Typography 
                  variant="body1" 
                  gutterBottom 
                  sx={{ width: '120px' }}>
                  Price: $
                </Typography>
                <TextField
                  variant="outlined"
                  value={appointment.price}
                  disabled={!priceEditEnable}
                  onChange={(e) => handlePriceChange(e.target.value)}
                  onBlur={disablePriceChange}
                  inputRef={priceTextFieldRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {priceEditEnable ? 'without tax' : 'with tax'}
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton onClick={enableEditPrice}>
                  <EditIcon />
                </IconButton>
              </Box>
              <Box 
                my={2} 
                display="flex" 
                alignItems="center">
                <Typography 
                  variant="body1" 
                  gutterBottom 
                  sx={{ width: '120px' }}>
                  Balance Paid: $
                </Typography>
                <TextField
                  variant="outlined"
                  value={appointment.balancePaid || 0}
                  onChange={(e) => handleAppointmentChange("balancePaid", e.target.value)}
                  sx={{ marginRight: '8px' }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate(`/payment/${appointment._id}`);
                  }}
                >
                  Pay Balance
                </Button>
              </Box>
              <Box 
                my={2} 
                display="flex" 
                alignItems="center">
                <Typography 
                  variant="body1" 
                  gutterBottom 
                  sx={{ width: '120px' }}>
                  Status:
                </Typography>
                <FormControl 
                  fullWidth>
                  <Select
                    value={appointment.status}
                    onChange={(e) => handleStatusChange(e.target.value)}
                    variant="outlined"
                  >
                    {statusOptions.map((status) => (
                      <MenuItem 
                        key={status} 
                        value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box 
                my={2} 
                display="flex" 
                alignItems="center">
                <Typography 
                  variant="body1" 
                  gutterBottom 
                  sx={{ width: '120px' }}>
                  Payment Due Date:
                </Typography>
                <DateTimePicker
                  value={appointment.paymentDueDateTime}
                  onChange={(newValue) => handleAppointmentChange("paymentDueDateTime", newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box 
                my={2} 
                display="flex" 
                alignItems="center">
                <Typography 
                  variant="body1" 
                  gutterBottom 
                  sx={{ width: '120px' }}>
                  Handyman {selectedProvider === null && "Unassigned"}:
                </Typography>
                <FormControl 
                  fullWidth>
                  <Select
                    value={appointment.providerId}
                    onChange={(e) => handleProviderChange(e.target.value)}
                    variant="outlined"
                  >
                    {providers.length > 0 &&
                      providers.map((provider) => (
                        <MenuItem 
                          key={provider._id} 
                          value={provider._id}>
                          {provider.firstName} {provider.lastName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Typography 
                variant="body1" 
                gutterBottom>
                {`Additional Instructions: ${appointment.additionalInstructions || 'N/A'}`}
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom>
                {`Referral Source: ${appointment.referralSource || 'N/A'}`}
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom>
                {`Coupon Code: ${appointment.couponCode || 'N/A'}`}
              </Typography>
              <Box 
                sx={{ flexGrow: 1, padding: 2 }} 
                my={2}>
                <Box 
                  display="flex" 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={2}>
                  <Typography 
                    variant="body1" 
                    gutterBottom>
                    Services:
                  </Typography>
                  <IconButton 
                    onClick={handleOpenServicesModal} 
                    color="primary"
                    variant="contained">
                    <AddIcon />
                  </IconButton>
                </Box>
                <Grid container spacing={2}>
                  {appointment.services &&
                    appointment.services.map((service, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                          {service ? (
                            <ServiceCard 
                              service={service} 
                              servicesLength={appointment.services.length}
                              onDeleteService={() => handleDeleteService(service)}
                              onUpdateImages={(newImages) => handleUpdateServiceImages(service._id, newImages)}
                              id={service._id}
                            />
                          ) : (
                            <>Service unavailable</>
                          )}
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
              {
                appointment.notes &&
                <AppointmentNotes notes={appointment.notes} />
              }
            </Paper>
          ) : (
            <Typography>
              Loading appointment details...
            </Typography>
          )}
          <AddServiceModal 
            open={openServicesModal} 
            onClose={handleCloseServicesModal} 
            onAdd={handleAddService} 
          />
          {
            appointment &&
            <DateTimeModal
              appointmentId={appointment._id}
              date={appointment.dateTime}
              providerId={appointment.providerId}
              duration={appointment.duration}
              open={openDateTimeModal} 
              onClose={handleCloseDateTimeModal} 
              handleAppointmentChange={handleAppointmentChange} 
            />
          }
          <JobCompletedFormModal 
            open={jobCompletedFormModalOpen}
            onClose={() => setJobCompletedFormModalOpen(false)}
            onSubmit={handleCompletedJobFormSubmit}
          />
        </Container>
      }
    </LocalizationProvider>
  );
};

export default Appointment;
