import React, { useEffect, useState } from 'react';
import { getCategories } from '../api/categories';
import { Box, Grid, useMediaQuery } from '@mui/material';

const Categories = ({ onCategoryClick }) => {
  const [categories, setCategories] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const categories = await getCategories();
    setCategories(categories);
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    onCategoryClick(categoryId);
  };

  const calculateGridColumns = () => {
    return isMobile ? 1 : 3;
  };

  return (
    <>
      <Grid container spacing={5}>
        {categories &&
          categories.map((category) => (
            <Grid item xs={12} sm={calculateGridColumns()} key={category._id}>
              <Box
                sx={{
                  bgcolor: 'white',
                  p: isMobile ? 2 : 5,
                  border: '1px solid',
                  borderColor: 'primary.main',
                  borderRadius: '4px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  ...(selectedCategoryId === category._id && {
                    bgcolor: 'primary.main',
                    color: 'white',
                  }),
                }}
                onClick={() => handleCategoryClick(category._id)}
              >
                {category.name}
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default Categories;
