import React, { useEffect, useState } from 'react';
import { getServices } from '../api/services';
import { Box, Grid, useMediaQuery } from '@mui/material';

const Services = ({ categoryId, activeStep, onServiceSelect }) => {
  const [levelOneservices, setLevelOneservices] = useState(null);
  const [levelTwoServices, setLevelTwoServices] = useState(null);
  const [levelThreeServices, setLevelThreeServices] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    fetchServices(activeStep, selectedServiceId, categoryId);
    // eslint-disable-next-line
  }, [activeStep]);

  const fetchServices = async (level, parentServiceId, categoryId) => {
    const services = await getServices(level, parentServiceId ?? null, !parentServiceId ? categoryId : null);
    setSelectedServiceId(null);
    if (level === 1 && !levelOneservices) {
      setLevelOneservices(services);
    } else if (level === 2 && !levelTwoServices) {
      setLevelTwoServices(services);
    } else if (level === 3) {
      setLevelThreeServices(services);
    }
  };

  const serviceClicked = async (level, service) => {
    setSelectedServiceId(service._id);
    onServiceSelect(service);
  };

  const ServiceLoop = (services, level) => {
    const columns = isMobile ? 1 : 3; // Determine number of columns based on screen width
    return (
      <>
        <Grid container spacing={5}>
          {services.map((service) => (
            <Grid item xs={12 / columns} key={service._id}>
              <Box
                sx={{
                  bgcolor: 'white',
                  p: 5,
                  border: '1px solid',
                  borderColor: 'primary.main',
                  borderRadius: '4px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  ...(selectedServiceId === service._id && {
                    bgcolor: 'primary.main',
                    color: 'white',
                  }),
                }}
                onClick={() => serviceClicked(level, service)}
              >
                <p>{service.name}</p>
                <p>{service.basePrice ? `$${service.basePrice}` : ``}</p>
                <p>{service.baseDuration ? `${service.baseDuration} Hours` : ``}</p>
                {service.imageUrl && <img src={service.imageUrl} alt="service" width={200} />}
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <>
      {activeStep === 1 && levelOneservices && ServiceLoop(levelOneservices, 2)}
      {activeStep === 2 && levelTwoServices && ServiceLoop(levelTwoServices, 3)}
      {activeStep === 3 && levelThreeServices && ServiceLoop(levelThreeServices, 4)}
    </>
  );
};

export default Services;
