import axios from './axiosConfig';

const getCoupons = async (queryParams = {}) => {
  try {
    const response = await axios.get('/coupons', { 
        params: queryParams 
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching coupons:", error);
    throw error;
  }
};

const createCoupon = async (couponData) => {
    try {
      const response = await axios.post('/coupon', couponData);
      return response.data;
    } catch (error) {
      console.error("Error creating coupon:", error);
      throw error;
    }
  };
  
const deleteCoupon = async (id) => {
    try {
      const response = await axios.delete('/coupon', {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting coupon:', error);
      throw error;
    }
};
  
const updateCouponToUsed = async (idOrCode) => {
    try {
      const response = await axios.put('/coupon', null, {
        params: idOrCode,
      });
      return response.data;
    } catch (error) {
      console.error('Error updating coupon:', error);
      throw error;
    }
};
  
  export { getCoupons, createCoupon, deleteCoupon, updateCouponToUsed };
