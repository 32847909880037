import axios from './axiosConfig';

const getMeta = async (id) => {
  try {
    const response = await axios.get('/meta', {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const getMetas = async () => {
  try {
    const response = await axios.get('/metas');
    return response.data.metas;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const createMeta = async (values) => {
  try {
    const response = await axios.post('/meta', values);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the meta:', error);
    throw error;
  }
};

const updateMeta = async (id, values) => {
  try {
    const response = await axios.put(`/meta/${id}`, values);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the meta:', error);
    throw error;
  }
};

const deleteMeta = async (id) => {
  try {
    const response = await axios.delete(`/meta/${id}`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the meta:', error);
    throw error;
  }
};

export { 
  getMeta, 
  getMetas, 
  createMeta, 
  updateMeta, 
  deleteMeta,
};
