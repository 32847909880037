import axios from './axiosConfig';

const getClient = async (id) => {
  try {
    const response = await axios.get('/client', {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;  // It is generally a good practice to throw the error to handle it later
  }
};

export { getClient };
