import axios from './axiosConfig';

const getProvider = async (id) => {
  try {
    const response = await axios.get('/provider', {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const getProviders = async () => {
  try {
    const response = await axios.get('/provider');
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const updateProvider = async (id, values) => {
  try {
    const response = await axios.put(`/provider/${id}`, {
      id,
      values
    });
    return response.data;
  } catch (error) {
    console.error('There was an error updating the provider:', error);
    throw error;
  }
};

const checkIfDateTimeOverlapsForProvider = async (appointmentId, dateTime, duration, providerId) => {
  try {
    const response = await axios.get('/provider/availability', {
      params: {
        appointmentId,
        dateTime,
        duration,
        providerId,
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const changePassword = async (email, currentPassword, newPassword) => {
  try {
    const response = await axios.put('/provider/change-password', {
      email,
      currentPassword,
      newPassword
    });
    return response;
  } catch (error) {
    console.error('There was an error changing the password:', error);
    throw error;
  }
};

export { getProvider, getProviders, checkIfDateTimeOverlapsForProvider, updateProvider, changePassword };
