import React, { useEffect, useState } from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  Chip, 
  Box, 
  IconButton,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ServiceCard = ({ 
  service, 
  onDeleteService, 
  servicesLength,
  onUpdateImages,
  id,
}) => {
  const { 
    name, 
    description, 
    basePrice, 
    baseDuration, 
    questionsAndAnswers, 
    images,
    note
  } = service;

  const beforeImages = images ? images.filter(image => image.type === 'Before') : [];
  const [afterImages, setAfterImages] = useState([]);

  useEffect(() => {
    const afterImages = images ? images.filter(image => image.type === 'After') : []
    setAfterImages(afterImages);
  }, [images]);

  const handleAddAfterImage = () => {
    document.getElementById(`afterServiceImageInput-${id}`).click();
  };

  const handleAfterImageChange = (event) => {
    const files = Array.from(event.target.files);
    const updatedImages = [...afterImages]; 
    files.forEach(file => {
      updatedImages.push({ type: 'After', file }); 
    });
    console.log(name)
    setAfterImages(updatedImages);
    onUpdateImages([...beforeImages, ...updatedImages]);
  };

  return (
    <Card sx={{ m: 2, p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardContent sx={{ textAlign: 'center', width: '100%' }}>
        <Typography 
          gutterBottom 
          variant="h5" 
          component="div">
          {name}
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          gutterBottom>
          {description}
        </Typography>
        <Typography 
          variant="body2" 
          color="text.primary">
          Duration: {baseDuration} hour(s)
        </Typography>
        <Box
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          marginBottom={2}
          marginTop={2}>
          <Chip 
            label={`$${basePrice}`} 
            color="primary" />
          {servicesLength > 1 && (
            <IconButton onClick={() => onDeleteService()}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        {questionsAndAnswers && (
          <Box mt={2}>
            {Object.entries(questionsAndAnswers).map(([question, answer], index) => (
              <Box key={index} mb={1}>
                <Typography variant="body2" color="text.secondary">
                  {question}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  Answer: {answer}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {note && (
          <Box mt={2} mb={2}>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              gutterBottom>
              Note:
            </Typography>
            <Typography 
              variant="body2" 
              color="text.primary">
              {note}
            </Typography>
          </Box>
        )}
        {beforeImages.length > 0 && (
          <Box mt={2} mb={2}>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              gutterBottom>
              Before Images:
            </Typography>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              {beforeImages.map((image, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  height="140"
                  image={image.url}
                  alt={`Before Image ${index + 1}`}
                  sx={{ m: 1, width: '100%', maxWidth: 180 }}
                />
              ))}
            </Box>
          </Box>
        )}
        {afterImages.length > 0 && (
           <Box mt={2} mb={2}>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              gutterBottom>
              After Images:
            </Typography>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              {afterImages.map((image, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  height="140"
                  image={image.file ? URL.createObjectURL(image.file) : image.url}
                  alt={`After Image ${index + 1}`}
                  sx={{ m: 1, width: '100%', maxWidth: 180 }}
                />
              ))}
            </Box>
          </Box>
        )}
        <input
          type="file"
          id={`afterServiceImageInput-${id}`}
          style={{ display: 'none' }}
          onChange={handleAfterImageChange}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button variant="contained" color="primary" onClick={handleAddAfterImage}>
            Add After Image
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
