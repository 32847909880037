import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastify } from '../utils';
import { 
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Paper,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { loginUser } from '../api/auth';
import { setToken } from '../utils';
import { useDispatch } from 'react-redux'; 
import { login } from '../redux/actions/authActions';

const Login = () => {
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { status, data } = await loginUser(formData);
      if (status === 202) {
        const { token, user } = data;
        const { isVerified } = user;
        if (isVerified) {
          setToken(token);
          dispatch(login(user));
          navigate('/appointments');
        } else {
          toastify('User Unverified', 'error');
        }
      } else {
        toastify('Unauthorized', 'error');
      }
    } catch (error) {
      toastify('Error', 'error');
    }
    setLoading(false);
  };

  return (
    <Container
      maxWidth="sm"
      style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        height: '100vh' }}>
      <Paper
        elevation={3}
        style={{ 
          padding: '20px',
          marginTop: '100px'
        }}>
        <form onSubmit={handleSubmit}>
          <Grid
            container 
            spacing={2} 
            justify="center">
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                value={formData.password}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              {
                !loading ?
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth>
                  Login
                </Button> :
                <CircularProgress/>
              }
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
