import React, { 
  useState, 
  useEffect, 
} from 'react';
import { 
  useNavigate, 
  useParams, 
} from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Input,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Switch,
  FormControlLabel,
  OutlinedInput,
  Chip,
  IconButton,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { 
  createBlog, 
  getBlogById, 
  updateBlogById, 
} from '../api/blogs';
import { getCategories } from '../api/categories';
import { uploadImage } from '../utils';
import tags from '../blogTags';
import CloseIcon from '@mui/icons-material/Close';

const AddOrEditBlog = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [content, setContent] = useState('');
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingBlog, setLoadingBlog] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [published, setPublished] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    fetchCategories();
    if (id) {
      fetchBlog(id);
    }
  }, [id]);

  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
      const fetchedCategories = await getCategories();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('There was an error fetching categories:', error);
    } finally {
      setLoadingCategories(false);
    }
  };

  const fetchBlog = async (blogId) => {
    setLoadingBlog(true);
    try {
      const blog = await getBlogById(blogId);
      setTitle(blog.title);
      setImageUrl(blog.imageUrl);
      setCategoryId(blog.categoryId);
      setContent(blog.content);
      setPublished(blog.published);
      setSelectedTags(blog.tags || []);
    } catch (error) {
      console.error('There was an error fetching the blog:', error);
    } finally {
      setLoadingBlog(false);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePublish = async () => {
    setPublishing(true);
    let newImageUrl = imageUrl;
    if (image) {
      newImageUrl = await uploadImage(image);
    }
    const blogData = {
      title,
      imageUrl: newImageUrl,
      categoryId,
      content,
      date: new Date().toISOString(),
      published,
      tags: selectedTags,
    };

    try {
      if (id) {
        await updateBlogById(id, blogData);
      } else {
        await createBlog(blogData);
      }
      navigate('/blogs');
    } catch (error) {
      console.error('There was an error publishing the blog:', error);
    } finally {
      setPublishing(false);
    }
  };

  const handleTagsChange = (event) => {
    const { target: { value } } = event;
    setSelectedTags(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleTagDelete = (tagToDelete) => {
    setSelectedTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  return (
    <Container>
      <Box my={4}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          color="primary"
        >
          {id ? 'Edit Blog' : 'Add Blog'}
        </Typography>
        {loadingBlog ? (
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            height="50vh">
            <CircularProgress />
          </Box>
        ) : (
          <Grid 
            container 
            spacing={2} 
            justifyContent="center">
            <Grid 
              item 
              xs={12} 
              sm={8}>
              <TextField
                label="Title"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                margin="normal"
                variant="outlined"
              />
              <Input
                type="file"
                onChange={handleImageChange}
                margin="normal"
                fullWidth
              />
              {imageUrl && !image && (
                <img 
                  src={imageUrl} 
                  alt="Current Blog" 
                  style={{ 
                    width: '100%', 
                    marginTop: '16px', 
                    marginBottom: '16px' 
                  }} 
                />
              )}
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel id="category-label">
                  Category
                </InputLabel>
                {loadingCategories ? (
                  <CircularProgress size={24} />
                ) : (
                  <Select
                    labelId="category-label"
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                    label="Category"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel id="tags-label">Tags</InputLabel>
                <Select
                  labelId="tags-label"
                  multiple
                  value={selectedTags}
                  onChange={handleTagsChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Tags" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={value}
                          deleteIcon={
                            <IconButton
                              aria-label="delete"
                              onMouseDown={(event) => event.stopPropagation()}
                              onClick={() => handleTagDelete(value)}
                            >
                              <CloseIcon />
                            </IconButton>
                          }
                        />
                      ))}
                    </Box>
                  )}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ReactQuill
                value={content}
                onChange={setContent}
                theme="snow"
                placeholder="Write your blog content here..."
                style={{ 
                  marginTop: '16px', 
                  marginBottom: '16px', 
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={published}
                    onChange={(e) => setPublished(e.target.checked)}
                  />
                }
                label="Publish"
              />
              <Box display="flex" justifyContent="center" mt={2}>
                {publishing ? (
                  <CircularProgress size={24} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePublish}
                    fullWidth
                  >
                    {id ? 'Update Blog' : 'Add Blog'}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default AddOrEditBlog;
