import React, { useEffect } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { 
  createTheme,
  ThemeProvider
} from '@mui/material/styles';
import { useDispatch } from 'react-redux'; 
import { useNavigate } from 'react-router-dom';
import { 
  getToken, 
  deleteToken, 
  inistializeGoogleCalendarAPI,
} from './utils';
import { Container } from '@mui/material';
import Header from './components/header';
import Login from './screens/Login';
import Appointments from './screens/Appointments';
import Appointment from './screens/Appointment';
import AddAppointment from './screens/AddAppointment';
import Calendar from './screens/Calendar';
import Handymen from './screens/Handymen';
import Services from './screens/Services';
import Payment from './screens/Payment';
import Profile from './screens/Profile';
import Summary from './screens/Summary';
import Coupons from './screens/Coupons';
import ChangePassword from './screens/ChangePassword';
import Blogs from './screens/Blogs';
import AddOrEditBlog from './screens/AddOrEditBlog';
import SEO from './screens/SEO';
import ProtectedRoute from './components/ProtectedRoute';
import { verifyUser, getUserById } from './api/auth';
import { login } from './redux/actions/authActions';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1c324f',
    },
    secondary: {
      main: '#f2c913',
    },
  },
});

const App = () => {
  const token = getToken();
  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  useEffect(() => {
    inistializeGoogleCalendarAPI();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      if(token) {
        try {
          const { status, data } = await verifyUser(token);
          if(status === 200) {
            const { userId } = data;
            const response = await getUserById(userId);
            dispatch(login(response.data.user));
          } else {
            deleteToken()
            navigate('/login');
          }
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    };
    fetchUser();
  }, [token, dispatch, navigate]);
  
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <ToastContainer />
      <Container>
        <Routes>
          <Route 
            path="/" 
            element={
              <Navigate 
                to="/appointments" 
                replace 
              />
            }
          />
          <Route 
            path="/login" 
            element={
              <Login />
            }
          />
          <Route 
            path="/summary" 
            element={
              <ProtectedRoute>
                <Summary />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/appointments" 
            element={
              <ProtectedRoute>
                <Appointments />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/appointments/:id" 
            element={
              <ProtectedRoute>
                <Appointment />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/addAppointment" 
            element={
              <ProtectedRoute>
                <AddAppointment />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/calendar" 
            element={
              <ProtectedRoute>
                <Calendar />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/handymen" 
            element={
              <ProtectedRoute>
                <Handymen />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/services" 
            element={
              <ProtectedRoute>
                <Services />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/payment/:appointmentId" 
            element={
              <Payment />
            }
          />
          <Route 
            path="/my-profile" 
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/coupons" 
            element={
              <ProtectedRoute>
                <Coupons />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/change-password" 
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/blogs" 
            element={
              <ProtectedRoute>
                <Blogs />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/blogs/add" 
            element={
              <ProtectedRoute>
                <AddOrEditBlog />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/blogs/:id" 
            element={
              <ProtectedRoute>
                <AddOrEditBlog />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/seo" 
            element={
              <ProtectedRoute>
                <SEO />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

export default App;
