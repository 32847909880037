import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteServiceFromCart } from '../redux/actions/cartActions';
import { subtractTaxFromPrice } from '../utils';
import { 
  Button, 
  Typography, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Box,
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Cart = ({ selectedDateTime, onCartAccept, onHandleBack }) => {
  const dispatch = useDispatch();
  const cartServices = useSelector((state) => state.cart.services);
  const totalBaseDuration = useSelector((state) => state.cart.totalBaseDuration);
  const totalPrice = useSelector((state) => state.cart.totalPrice);

  const deleteService = (service) => {
    dispatch(deleteServiceFromCart(service));
  };

  return (
    <>
      <IconButton 
        color="primary" 
        onClick={() => onHandleBack()}>
        <ArrowBackIcon />
      </IconButton>
      <Typography 
        variant="h5" 
        gutterBottom 
        color="primary">
        Cart
      </Typography>
      <Typography 
        variant="body1" 
        gutterBottom 
        color="primary">
        <b>Date:</b> {selectedDateTime.toString()}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartServices.map((service) => (
            <TableRow key={service._id}>
              <TableCell>{service.name}</TableCell>
              <TableCell>{service.baseDuration} hours</TableCell>
              <TableCell>${service.basePrice}</TableCell>
              <TableCell>
                {cartServices.length > 1 && (
                  <Button 
                    variant="outlined" 
                    color="error" 
                    onClick={() => deleteService(service)}>
                    Delete
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell><b>Total</b></TableCell>
            <TableCell>
              {totalBaseDuration} Hours 
            </TableCell>
            <TableCell>
              ${subtractTaxFromPrice(totalPrice, 13)} + 13% HST<br />
              <b>${totalPrice.toFixed(2)}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box mt={2}>
        <Button 
          variant="contained" 
          fullWidth 
          color="primary" 
          onClick={() => onCartAccept()}>
          Accept
        </Button>
      </Box>
    </>
  )
}

export default Cart;
