import { addTaxToPrice } from '../../utils';

const initialState = {
  services: [],
  totalBaseDuration: 0,
  totalPrice: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        services: [...state.services, action.payload],
        totalBaseDuration: state.totalBaseDuration + action.payload.baseDuration,
        totalPrice: parseInt(state.totalPrice) + parseInt(addTaxToPrice(action.payload.basePrice, 13)),
      };
    case 'DELETE':
      const index = state.services.findIndex(service => service.id === action.payload.id);
      if (index !== -1) {
        const deletedService = state.services[index];
        return {
          ...state,
          services: state.services.filter((_, i) => i !== index), // Create a new array without the deleted service
          totalBaseDuration: state.totalBaseDuration - deletedService.baseDuration,
          totalPrice: state.totalPrice - addTaxToPrice(deletedService.basePrice, 13)
        };
      };
      return state;
    case 'CLEAR':
      return {
        services: [],
        totalBaseDuration: 0,
        totalPrice: 0,
      };
    default:
      return state;
  }
};

export default cartReducer;
