import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Checkbox, FormControlLabel, Button } from '@mui/material';

const JobCompletedFormModal = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    additionalMaterials: false,
    additionalMaterialsDetails: '',
    jobDurationExceeded: false,
    jobDurationExceedReason: '',
    issuesEncountered: false,
    issuesDescription: '',
    followUpRequired: false,
    followUpReasons: '',
    recommendations: '',
    paymentReceived: false,
    customerRequests: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const validateForm = () => {
    const errors = {};
    if (formData.additionalMaterials && !formData.additionalMaterialsDetails) {
      errors.additionalMaterialsDetails = 'Please describe the addional tools required';
    }
    if (formData.jobDurationExceeded && !formData.jobDurationExceedReason) {
      errors.jobDurationExceedReason = 'Please describe the reason the job duration was exceeded';
    }
    if (formData.issuesEncountered && !formData.issuesDescription) {
      errors.issuesDescription = 'Please describe the issues encountered';
    }
    if (formData.followUpRequired && !formData.followUpReasons) {
      errors.followUpReasons = 'Please specify reasons for follow-up';
    }
    if (!formData.paymentReceived && !formData.paymentExplanation) {
      errors.paymentExplanation = 'Please explain why payment was not received';
    }
    if (!formData.customerRequests) {
      errors.customerRequests = 'Please enter any additional customer requests, concerns or comments';
    }
    if (!formData.recommendations) {
      errors.recommendations = 'Please enter your own observations';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
    if (submitAttempted) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitAttempted(true);
    if (!validateForm()) {
      event.stopPropagation();
      return;
    }
    onSubmit(formData);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setFormErrors({});
        setSubmitAttempted(false);
      }}
      aria-labelledby="job-form-modal-title"
      aria-describedby="job-form-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          position: 'absolute',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <Typography id="job-form-modal-title" variant="h6" component="h2" align="center">
          Job Details Form
        </Typography>
        <FormControlLabel 
          control={<Checkbox checked={formData.additionalMaterials} onChange={handleChange} name="additionalMaterials" />} 
          label="Were additional materials or tools required that you did not have?" 
        />
        {formData.additionalMaterials && <TextField 
          multiline
          rows={4}
          margin="normal" 
          fullWidth 
          name="additionalMaterialsDetails" 
          label="If yes, please specify" 
          value={formData.additionalMaterialsDetails} 
          onChange={handleChange} 
          required
          error={!!formErrors.additionalMaterialsDetails}
          helperText={formErrors.additionalMaterialsDetails || ''}
        />}
        <FormControlLabel 
          control={<Checkbox checked={formData.jobDurationExceeded} onChange={handleChange} name="jobDurationExceeded" />} 
          label="Did the job take longer than planned?" 
        />
        {formData.jobDurationExceeded && <TextField 
          multiline
          rows={4}
          margin="normal" 
          fullWidth 
          name="jobDurationExceedReason" 
          label="If so, explain" 
          value={formData.jobDurationExceedReason} 
          onChange={handleChange} 
          required
          error={!!formErrors.jobDurationExceedReason}
          helperText={formErrors.jobDurationExceedReason || ''}
        />}
        <FormControlLabel 
          control={<Checkbox checked={formData.issuesEncountered} onChange={handleChange} name="issuesEncountered" />} 
          label="Any issues encountered during the job?" 
        />
        {formData.issuesEncountered && <TextField 
          multiline
          rows={4}
          margin="normal" 
          fullWidth 
          name="issuesDescription" 
          label="If yes, please describe" 
          value={formData.issuesDescription} 
          onChange={handleChange} 
          required
          error={!!formErrors.issuesDescription}
          helperText={formErrors.issuesDescription || ''}
        />}
        <FormControlLabel 
          control={<Checkbox checked={formData.followUpRequired} onChange={handleChange} name="followUpRequired" />} 
          label="Is a follow-up visit required?" 
        />
        {formData.followUpRequired && <TextField
          multiline
          rows={4}
          margin="normal" 
          fullWidth 
          name="followUpReasons" 
          label="If yes, please specify reasons" 
          value={formData.followUpReasons} 
          onChange={handleChange} 
          required
          error={!!formErrors.followUpReasons}
          helperText={formErrors.followUpReasons || ''}
        />}
        <FormControlLabel 
          control={<Checkbox checked={formData.paymentReceived} onChange={handleChange} name="paymentReceived" />} 
          label="Payment received?" 
        />
        {!formData.paymentReceived && <TextField 
          multiline
          rows={4}
          margin="normal" 
          fullWidth 
          name="paymentExplanation" 
          label="If no, explain" 
          value={formData.paymentExplanation} 
          onChange={handleChange} 
          required
          error={!!formErrors.paymentExplanation}
          helperText={formErrors.paymentExplanation || ''}
        />}
        <TextField 
          multiline
          rows={4}
          margin="normal" 
          fullWidth 
          name="customerRequests" 
          label="Customer's additional requests or concerns" 
          value={formData.customerRequests} 
          onChange={handleChange} 
          required 
          error={!!formErrors.customerRequests}
          helperText={formErrors.customerRequests || ''}
        />
        <TextField 
          multiline
          rows={4}
          margin="normal" 
          fullWidth 
          name="recommendations" 
          label="Technician's Observations and Suggestions" 
          value={formData.recommendations} 
          onChange={handleChange}
          required 
          error={!!formErrors.recommendations}
          helperText={formErrors.recommendations || ''}
        />
        <Box display="flex" justifyContent="space-evenly" mt={3}>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default JobCompletedFormModal;
