import React, { useEffect, useState } from 'react';
import { Typography, Container, Box, Paper, Grid, CircularProgress, MenuItem, Select } from '@mui/material';
import { getAppointments } from '../api/appointments';
import { hoursToHoursAndMins } from '../utils';
import { getService } from '../api/services';

const Summary = () => {
  const [totalCountAppointmentsCreatedFrom, setTotalCountAppointmentsCreatedFrom] = useState(0);
  const [totalCountAppointmentsCompleted, setTotalCountAppointmentsCompleted] = useState(0);
  const [totalCountAppointmentsCancelled, setTotalCountAppointmentsCancelled] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [averagePrice, setAveragePrice] = useState(0);
  const [totalDuration, setTotalDuration] = useState({ hours: 0, minutes: 0 });
  const [averageDuration, setAverageDuration] = useState({ hours: 0, minutes: 0 });
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('Week');
  const getStartDateOfWeek = () => {
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // One week ago from today
    const dayOfWeek = oneWeekAgo.getDay();
    const diff = oneWeekAgo.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when day is Sunday
    const startDateOfWeek = new Date(oneWeekAgo.setDate(diff));
    startDateOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day
    return startDateOfWeek;
  };
  const [from, setFrom] = useState(getStartDateOfWeek());
  const to = new Date().toISOString();
  const [servicesIds, setServicesIds] = useState([]);
  const [services, setServices] = useState([]);
  const [top3Services, setTop3Services] = useState([]);

  useEffect(() => {
    updateDates();
  }, [timeRange]);

  useEffect(() => {
    fetchData();
  }, [from])

  const updateDates = () => {
    let fromDate = new Date();
    switch (timeRange) {
      case 'Month':
        fromDate.setMonth(fromDate.getMonth() - 1);
        break;
      case 'Quarter':
        fromDate.setMonth(fromDate.getMonth() - 3);
        break;
      default:
        fromDate = getStartDateOfWeek();
        break;
    }
    setFrom(fromDate.toISOString());
  };

  const handleChangeTimeRange = (event) => {
    setTimeRange(event.target.value);
  };

  useEffect(() => {
    if (servicesIds) {
      fetchServices(servicesIds)
    }
  }, [servicesIds]);


  useEffect(() => {
    if (services.length > 0) {
      const serviceCounts = services.reduce((counts, service) => {
        counts[service.name] = (counts[service.name] || 0) + 1;
        return counts;
      }, {});
      const sortedServices = Object.keys(serviceCounts).sort((a, b) => serviceCounts[b] - serviceCounts[a]);
      const top3ServicesWithFrequency = sortedServices.slice(0, 3).map(serviceName => ({
        name: serviceName,
        frequency: serviceCounts[serviceName]
      }));
      setTop3Services(top3ServicesWithFrequency);
    }
  }, [services]);  

  const fetchServices = async (serviceIds) => {
    try {
      const servicesArray = [];
      for (const serviceId of serviceIds) {
        const response = await getService(serviceId);
        servicesArray.push(response);
      }
      setServices(servicesArray);
    } catch (error) {
      console.error("There was an error fetching services:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const createdResponse = await getAppointments({ createdFrom: from, createdTo: to });
      const scheduledResponse = await getAppointments({ scheduledFrom: from, scheduledTo: to });

      const createdAppointments = createdResponse.appointments;
      const scheduledAppointments = scheduledResponse.appointments;

      const ids = [];
      createdAppointments.forEach(appointment => {
        ids.push(...appointment.services.map(service => service._id));
      });
      setServicesIds(ids);

      const requestedCount = createdAppointments.length;
      const completedCount = scheduledAppointments.filter(appointment => appointment.status === 'Completed').length;
      const cancelledCount = scheduledAppointments.filter(appointment => appointment.status === 'Cancelled').length;

      const earnings = scheduledAppointments.filter(appointment => appointment.status === 'Completed').reduce((total, appointment) => {
        return total + appointment.balancePaid;
      }, 0);

      const totalDurationHours = scheduledAppointments.filter(appointment => appointment.status === 'Completed').reduce((total, appointment) => {
        return total + appointment.duration;
      }, 0);

      const averageDurationHours = scheduledAppointments.length > 0 ? totalDurationHours / scheduledAppointments.length : 0;

      setTotalCountAppointmentsCreatedFrom(requestedCount);
      setTotalCountAppointmentsCompleted(completedCount);
      setTotalCountAppointmentsCancelled(cancelledCount);
      setTotalEarnings(earnings);
      const avgEarnings = earnings / scheduledAppointments.length;
      if (!isNaN(avgEarnings)) {
        setAveragePrice(avgEarnings);
      } else {
        setAveragePrice(0);
      }
      setTotalDuration(hoursToHoursAndMins(totalDurationHours));
      setAverageDuration(hoursToHoursAndMins(averageDurationHours));
    } catch (error) {
      console.error('Error fetching appointments:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h4" gutterBottom color="primary">
          Summary
        </Typography>
        <Select value={timeRange} onChange={handleChangeTimeRange}>
          <MenuItem value="Week">This Week</MenuItem>
          <MenuItem value="Month">This Month</MenuItem>
          <MenuItem value="Quarter">This Quarter</MenuItem>
        </Select>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Jobs</Typography>
                <Typography variant="subtitle1">Requested: {totalCountAppointmentsCreatedFrom}</Typography>
                <Typography variant="subtitle1">Completed: {totalCountAppointmentsCompleted}</Typography>
                <Typography variant="subtitle1">Cancelled: {totalCountAppointmentsCancelled}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Earnings</Typography>
                <Typography variant="subtitle1">Total: ${totalEarnings}</Typography>
                <Typography variant="subtitle1">Average: ${averagePrice.toFixed(2)}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Job Duration</Typography>
                <Typography variant="subtitle1">Total: {totalDuration.hours} hours {totalDuration.minutes} mins</Typography>
                <Typography variant="subtitle1">Average: {averageDuration.hours} hours {averageDuration.minutes} mins</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Top 3 Services</Typography>
                {top3Services.map((service, index) => (
                  <Typography key={index} variant="subtitle1">
                    {service.name}: {service.frequency}
                  </Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Summary;
