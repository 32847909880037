import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  getCategories,
  editCategory,
  deleteCategoryById,
  createCategory,
} from '../api/categories';
import {
  getServices,
  editService,
  deleteServiceById,
  createService,
} from '../api/services';
import { uploadImage } from '../utils';

const Services = () => {
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [editedServiceDetails, setEditedServiceDetails] = useState({});
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [editedCategoryDetails, setEditedCategoryDetails] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [newCategoryDetails, setNewCategoryDetails] = useState({});
  const [showAddService, setShowAddService] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [newServiceDetails, setNewServiceDetails] = useState({});
  const [imageToAdd, setImageToAdd] = useState(null);
  const [questions, setQuestions] = useState({});

  useEffect(() => {
    const fetchCategoriesAndServices = async () => {
      const fetchedCategories = await getCategories();
      const fetchedServices = await getServices();
      setCategories(fetchedCategories);
      setServices(fetchedServices);
    };

    fetchCategoriesAndServices();
  }, []);

  const handleEditChange = (serviceId, field, value) => {
    setEditedServiceDetails((prev) => ({
      ...prev,
      [serviceId]: { ...prev[serviceId], [field]: value },
    }));
  };

  const handleEditCategoryChange = (categoryId, field, value) => {
    setEditedCategoryDetails((prev) => ({
      ...prev,
      [categoryId]: { ...prev[categoryId], [field]: value },
    }));
  };

  const handleQuestionChange = (serviceId, index, value) => {
    setQuestions((prev) => {
      const updatedQuestions = [...(prev[serviceId] || [])];
      updatedQuestions[index] = value;
      return { ...prev, [serviceId]: updatedQuestions };
    });
    setEditedServiceDetails((prev) => ({
      ...prev,
      [serviceId]: { ...prev[serviceId], questions: questions[serviceId] },
    }));
  };

  const handleAddQuestion = (serviceId) => {
    setQuestions((prev) => {
      const updatedQuestions = [...(prev[serviceId] || []), ''];
      return { ...prev, [serviceId]: updatedQuestions };
    });
  };

  const handleRemoveQuestion = (serviceId, index) => {
    setQuestions((prev) => {
      const updatedQuestions = prev[serviceId].filter((_, i) => i !== index);
      setEditedServiceDetails((prev) => ({
        ...prev,
        [serviceId]: { ...prev[serviceId], questions: updatedQuestions },
      }));
      return { ...prev, [serviceId]: updatedQuestions };
    });
  };

  const saveService = async (serviceId) => {
    const serviceDetails = {
      ...editedServiceDetails[serviceId],
      questions: questions[serviceId] || [],
    };
    try {
      if (imageToAdd) {
        const url = await uploadImage(imageToAdd);
        serviceDetails.imageUrl = url;
      }
      await editService(serviceId, serviceDetails);
      const updatedServices = services.map((service) =>
        service._id === serviceDetails._id ? serviceDetails : service
      );
      setServices(updatedServices);
      setEditingServiceId(null);
      setEditedServiceDetails((prev) => {
        const newState = { ...prev };
        delete newState[serviceId];
        return newState;
      });
      setImageToAdd(null);
    } catch (error) {
      console.error('Error updating service:', error);
    }
  };

  const saveCategory = async (categoryId) => {
    const categoryDetails = editedCategoryDetails[categoryId];
    try {
      if (imageToAdd) {
        const url = await uploadImage(imageToAdd);
        categoryDetails.imageUrl = url;
      }
      await editCategory(categoryId, categoryDetails);
      const updatedCategories = categories.map((category) =>
        category._id === categoryDetails._id ? categoryDetails : category
      );
      setCategories(updatedCategories);
      setEditingCategoryId(null);
      setEditedCategoryDetails((prev) => {
        const newState = { ...prev };
        delete newState[categoryId];
        return newState;
      });
      setImageToAdd(null);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const deleteService = async (serviceId) => {
    try {
      await deleteServiceById(serviceId);
      setServices((currentServices) => currentServices.filter((service) => service._id !== serviceId));
      setServiceToDelete(null);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      await deleteCategoryById(categoryId);
      setCategories((currentCategories) => currentCategories.filter((category) => category._id !== categoryId));
      setCategoryToDelete(null);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleDeleteClick = (serviceId) => {
    setOpenDeleteDialog(true);
    setServiceToDelete(serviceId);
  };

  const handleDeleteCategoryClick = (categoryId) => {
    setOpenDeleteDialog(true);
    setCategoryToDelete(categoryId);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
    await deleteService(serviceToDelete);
    setServiceToDelete(null);
    setOpenDeleteDialog(false);
  };

  const confirmDeletecategory = async () => {
    await deleteCategory(categoryToDelete);
    setCategoryToDelete(null);
    setOpenDeleteDialog(false);
  };

  const startEditing = (serviceId) => {
    const service = services.find((s) => s._id === serviceId);
    setEditedServiceDetails((prev) => ({
      ...prev,
      [serviceId]: { ...service },
    }));
    setQuestions((prev) => ({
      ...prev,
      [serviceId]: service.questions || [],
    }));
    setEditingServiceId(serviceId);
  };

  const startEditingCategory = (categoryId) => {
    const category = categories.find((s) => s._id === categoryId);
    setEditedCategoryDetails((prev) => ({
      ...prev,
      [categoryId]: { ...category },
    }));
    setEditingCategoryId(categoryId);
  };

  const handleCancelCategory = (category) => {
    setEditedCategoryDetails((prev) => {
      const newState = { ...prev };
      delete newState[category._id];
      return newState;
    });
    setEditingCategoryId(null);
  };

  const addCategory = async () => {
    try {
      const newCategory = await createCategory(newCategoryDetails);
      setCategories([...categories, newCategory]);
      setShowAddCategory(false);
      setNewCategoryDetails({});
    } catch (error) {
      console.error('Error adding new category:', error);
    }
  };

  const addService = async (service) => {
    try {
      if (service.categoryId) {
        service.level = 1;
      }
      if (imageToAdd) {
        const url = await uploadImage(imageToAdd);
        service.imageUrl = url;
      }
      const newService = await createService(service);
      setServices([...services, newService]);
      setShowAddService(false);
      setNewServiceDetails({});
      setImageToAdd(null);
    } catch (error) {
      console.error('Error adding new service:', error);
    }
  };

  const handleAddImage = () => {
    document.getElementById('imageInput').click();
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files) {
      const image = files[0];
      setImageToAdd(image);
    }
  };

  const AddServiceForm = ({ serviceDetails, onCancel, onSave }) => {
    const [localDetails, setLocalDetails] = useState(serviceDetails);
    const [questions, setQuestions] = useState(serviceDetails.questions || []);

    useEffect(() => {
      setLocalDetails(serviceDetails);
    }, [serviceDetails]);

    const handleChange = (field, value) => {
      setLocalDetails((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
      onSave({ ...localDetails, questions });
    };

    const handleQuestionChange = (index, value) => {
      const updatedQuestions = [...questions];
      updatedQuestions[index] = value;
      setQuestions(updatedQuestions);
    };

    const handleAddQuestion = () => {
      setQuestions([...questions, '']);
    };

    const handleRemoveQuestion = (index) => {
      const updatedQuestions = questions.filter((_, i) => i !== index);
      setQuestions(updatedQuestions);
    };

    return (
      <Box padding={2}>
        <Box display="flex" flexDirection="column" mt={2}>
          <Box
            mt={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 2,
            }}
          >
            <img src={imageToAdd ? URL.createObjectURL(imageToAdd) : ''} style={{ width: 200, maxHeight: 200 }} />
          </Box>
          <Button variant="contained" component="label" onClick={handleAddImage}>
            Choose Service Image
            <input type="file" id="imageInput" multiple style={{ display: 'none' }} onChange={handleImageChange} />
          </Button>
        </Box>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={localDetails.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={localDetails.description}
          onChange={(e) => handleChange('description', e.target.value)}
        />
        <TextField
          label="About"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={localDetails.about}
          onChange={(e) => handleChange('about', e.target.value)}
        />
        {localDetails.level === 3 && (
          <TextField
            label="Price"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={localDetails.basePrice}
            onChange={(e) => handleChange('basePrice', e.target.value)}
          />
        )}
        {localDetails.level === 3 && (
          <TextField
            label="Duration (hours)"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={serviceDetails.baseDuration}
            onChange={(e) => handleChange('baseDuration', e.target.value)}
          />
        )}
        {localDetails.level === 2 && (
          <>
            <Typography variant="h6" component="h2" gutterBottom>
              Questions
            </Typography>
            {questions.map((question, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  label={`Question ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  value={question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                />
                <IconButton onClick={() => handleRemoveQuestion(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button variant="contained" onClick={handleAddQuestion} startIcon={<AddIcon />}>
              Add Question
            </Button>
          </>
        )}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button onClick={onCancel} color="primary" variant="outlined" sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </Box>
      </Box>
    );
  };

  const renderServiceDetails = (service) => {
    const isEditing = editingServiceId === service._id;
    const serviceDetails = editedServiceDetails[service._id] || service;

    return (
      <>
        {isEditing ? (
          <>
            <Box display="flex" flexDirection="column" mt={2}>
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  my: 2,
                }}
              >
                <img
                  src={imageToAdd ? URL.createObjectURL(imageToAdd) : serviceDetails.imageUrl}
                  alt="Image"
                  style={{ width: 200, maxHeight: 200 }}
                />
              </Box>
              <Button variant="contained" component="label" onClick={handleAddImage}>
                Choose Service Image
                <input type="file" id="imageInput" multiple style={{ display: 'none' }} onChange={handleImageChange} />
              </Button>
            </Box>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={serviceDetails.name}
              onChange={(e) => handleEditChange(service._id, 'name', e.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={serviceDetails.description}
              onChange={(e) => handleEditChange(service._id, 'description', e.target.value)}
            />
            <TextField
              label="About"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={serviceDetails.about}
              onChange={(e) => handleEditChange(service._id, 'about', e.target.value)}
            />
            {'basePrice' in service && (
              <TextField
                label="Price"
                variant="outlined"
                fullWidth
                margin="normal"
                type="number"
                value={serviceDetails.basePrice}
                onChange={(e) => handleEditChange(service._id, 'basePrice', e.target.value)}
              />
            )}
            {'baseDuration' in service && (
              <TextField
                label="Duration (hours)"
                variant="outlined"
                fullWidth
                margin="normal"
                type="number"
                value={serviceDetails.baseDuration}
                onChange={(e) => handleEditChange(service._id, 'baseDuration', e.target.value)}
              />
            )}
            {service.level === 2 && (
              <>
                <Typography variant="h6" component="h2" gutterBottom>
                  Questions
                </Typography>
                {questions[service._id]?.map((question, index) => (
                  <Box key={index} display="flex" alignItems="center" mb={2}>
                    <TextField
                      label={`Question ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      value={question}
                      onChange={(e) => handleQuestionChange(service._id, index, e.target.value)}
                    />
                    <IconButton onClick={() => handleRemoveQuestion(service._id, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button variant="contained" onClick={() => handleAddQuestion(service._id)} startIcon={<AddIcon />}>
                  Add Question
                </Button>
              </>
            )}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button onClick={() => setEditingServiceId(null)} color="primary" variant="outlined" sx={{ marginRight: 2 }}>
                Cancel
              </Button>
              <Button onClick={() => saveService(service._id)} color="primary" variant="contained">
                Save
              </Button>
            </Box>
          </>
        ) : (
          <>
            {service.imageUrl && (
              <>
                <Typography variant='h5'>Service Image</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 2,
                  }}
                >
                  <img alt={service.name} src={serviceDetails.imageUrl} style={{ width: 200, height: 200 }} />
                </Box>
              </>
            )}
            <Typography variant='h5'>Description</Typography>
            <Typography paragraph>{serviceDetails.description}</Typography>
            <br/>
            <Typography variant='h5'>About</Typography>
            <Typography paragraph>{serviceDetails.about}</Typography>
            <br/>
            {service.basePrice && <Typography paragraph>{`Price: $${serviceDetails.basePrice}`}</Typography>}
            {service.baseDuration && <Typography paragraph>{`Duration: ${serviceDetails.baseDuration} hours`}</Typography>}
            {service.level === 2 && (
              <>
                <Typography variant="h6" component="h2" gutterBottom>
                  Questions
                </Typography>
                {service.questions.map((question, index) => (
                  <Typography key={index} paragraph>{`${index + 1}. ${question}`}</Typography>
                ))}
              </>
            )}
            {showAddTask && newServiceDetails.parentServiceId === service._id && (
              <AddServiceForm
                serviceDetails={newServiceDetails}
                onCancel={() => setShowAddService(false)}
                onSave={(serviceDetails) => {
                  addService(serviceDetails);
                  setShowAddService(false);
                }}
              />
            )}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <IconButton onClick={() => startEditing(service._id)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteClick(service._id)}>
                <DeleteIcon />
              </IconButton>
              {service.level !== 3 && (
                <>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setShowAddTask(true);
                      setNewServiceDetails((prev) => ({ ...prev, parentServiceId: service._id, level: service.level + 1 }));
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add {service.level === 2 ? 'Task Detail' : 'Task'}
                  </Button>
                </>
              )}
            </Box>
          </>
        )}
      </>
    );
  };

  const renderServices = (servicesList, level, parentId = null) => {
    return servicesList
      .filter(
        (service) =>
          (service.level === level && service.parentServiceId === parentId) ||
          (service.level === level && service.categoryId === parentId)
      )
      .map((service) => (
        <Accordion key={service._id} sx={{ my: 1, border: 1, borderColor: 'divider' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: 'primary.light',
              '.MuiAccordionSummary-expandIconWrapper': { color: 'primary.contrastText' },
              '.MuiTypography-root': { color: 'primary.contrastText' },
            }}
          >
            <Typography>{service.name}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: 'column' }}>
            {renderServiceDetails(service)}
            {service.level < 3 && renderServices(services, service.level + 1, service._id)}
          </AccordionDetails>
        </Accordion>
      ));
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom align="center" color="primary" sx={{ fontWeight: 'bold' }}>
          Services
        </Typography>
      </Box>
      {categories.length > 0 &&
        categories.map((category) => (
          <Accordion key={category._id} sx={{ my: 1, border: 1, borderColor: 'divider' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: 'primary.main',
                '.MuiAccordionSummary-expandIconWrapper': { color: 'primary.contrastText' },
                '.MuiTypography-root': { color: 'primary.contrastText' },
              }}
            >
              <Typography>{category.name}</Typography>
            </AccordionSummary>
            {editingCategoryId === category._id ? (
              <Box padding={2}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={editedCategoryDetails[category._id].name || category.name}
                  onChange={(e) => handleEditCategoryChange(category._id, 'name', e.target.value)}
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={editedCategoryDetails[category._id].description || category.description}
                  onChange={(e) => handleEditCategoryChange(category._id, 'description', e.target.value)}
                />
                <Box display="flex" flexDirection="column" mt={2}>
                  <Box
                    mt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      my: 2,
                    }}
                  >
                    <img src={imageToAdd ? URL.createObjectURL(imageToAdd) : ''} style={{ width: 200, maxHeight: 200 }} />
                  </Box>
                  <Button variant="contained" component="label" onClick={handleAddImage}>
                    Choose File
                    <input type="file" id="imageInput" multiple style={{ display: 'none' }} onChange={handleImageChange} />
                  </Button>
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button onClick={() => handleCancelCategory(category)} color="primary" variant="outlined" sx={{ marginRight: 2 }}>
                    Cancel
                  </Button>
                  <Button onClick={() => saveCategory(category._id)} color="primary" variant="contained">
                    Save
                  </Button>
                </Box>
              </Box>
            ) : (
              <AccordionDetails>
                <Typography paragraph>
                  {category.description}
                </Typography>
                {category.imageUrl && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      my: 2,
                    }}
                  >
                    <img alt={category.name} src={category.imageUrl} style={{ width: 200, height: 200 }} />
                  </Box>
                )}
                {showAddService && newServiceDetails.categoryId === category._id && (
                  <AddServiceForm
                    serviceDetails={newServiceDetails}
                    onCancel={() => setShowAddService(false)}
                    onSave={(serviceDetails) => {
                      addService(serviceDetails);
                      setShowAddService(false);
                    }}
                  />
                )}
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <IconButton onClick={() => startEditingCategory(category._id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteCategoryClick(category._id)}>
                    <DeleteIcon />
                  </IconButton>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setShowAddService(true);
                      setNewServiceDetails((prev) => ({ ...prev, categoryId: category._id }));
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add Service
                  </Button>
                </Box>
                {renderServices(services, 1, category._id)}
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      <Box my={2} display="flex" justifyContent="flex-end">
        <Button startIcon={<AddIcon />} onClick={() => setShowAddCategory(true)} variant="contained" color="primary">
          Add Category
        </Button>
      </Box>
      {showAddCategory && (
        <Accordion expanded={true}>
          <AccordionDetails>
            <Box padding={2}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newCategoryDetails.name}
                onChange={(e) => setNewCategoryDetails((prev) => ({ ...prev, name: e.target.value }))}
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={newCategoryDetails.description}
                onChange={(e) => setNewCategoryDetails((prev) => ({ ...prev, description: e.target.value }))}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button onClick={() => setShowAddCategory(false)} color="primary" variant="outlined" sx={{ marginRight: 2 }}>
                  Cancel
                </Button>
                <Button onClick={addCategory} color="primary" variant="contained">
                  Save
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <Dialog open={openDeleteDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {categoryToDelete ? 'category' : 'service'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          {categoryToDelete ? (
            <Button onClick={confirmDeletecategory} color="primary" autoFocus>
              Delete
            </Button>
          ) : (
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Services;
