import axios from './axiosConfig';

const getAppointments = async (data) => {
  try {
    const response = await axios.get('/appointments', {
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const getAppointment = async (id) => {
  try {
    const response = await axios.get('/appointment', {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const addAppointment = async (data, clientInfo) => {
  try {
    const response = await axios.post('/appointment', {
      data,
      clientInfo,
    });
    return response.data.appointment;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const updateAppointment = async (body, appointmentId) => {
  try {
    const response = await axios.put(`/appointment/${appointmentId}`, body);
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

export { getAppointments, getAppointment, addAppointment, updateAppointment };
