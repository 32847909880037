import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Categories from '../components/Categories';
import Services from '../components/Services';
import Checkout from '../components/Checkout';
import Calendar from '../components/Calendar';
import Cart from '../components/Cart';
import Details from '../components/Details';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { 
  Button,
  Typography, 
  Box, 
  Container,
  CircularProgress
} from '@mui/material';
import { addServiceToCart, clearCart } from '../redux/actions/cartActions';
import { getFormattedAddress } from '../utils';
import { addAppointment as addAppointmentFunciton,  } from '../api/appointments';

const AddAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stage, setStage] = useState('Services');
  const cartServices = useSelector((state) => state.cart.services);
  const totalBaseDuration = useSelector((state) => state.cart.totalBaseDuration);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedService, setselectedService] = useState(null);
  const [selectedDateTime, setselectedDateTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  const handleServiceClick = (service) => {
    setselectedService(service);
  };

  const handleNext = () => {
    if (activeStep === 3) {
      addService();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const addService = () => {
    dispatch(addServiceToCart(selectedService));
    setSelectedCategoryId(null);
    setselectedService(null);
    setActiveStep(0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedCategoryId(null);
    setselectedService(null);
  };

  const handleSelectSlot = (date) => {
    setselectedDateTime(date);
    setStage('Cart');
  };

  const handleConfirm = async (clientInfo) => {
    setLoading(true);
    const providerId = null;
    const clientId = null;
    const price = totalPrice;
    const balancePaid = 0;
    const dateTime = selectedDateTime;
    const duration = totalBaseDuration;
    const address = getFormattedAddress(clientInfo);
    const status = dateTime ? 'Pending': 'Pending Custom';
    const { additionalInstructions, referralSource } = clientInfo;

    const data = {
      services: cartServices,
      providerId,
      clientId,
      price,
      balancePaid,
      dateTime,
      duration,
      address,
      status,
      additionalInstructions,
      referralSource,
    };

    const { _id } = await addAppointmentFunciton(data, clientInfo);
    if (_id) {
      navigate(`/appointments`);
      dispatch(clearCart());
    };
    setLoading(false);
  };

  return (
    <>
      {
        loading ?
        <Box
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh' 
          }}
        >
          <CircularProgress size={100} />
        </Box> :
        <Container>
          <Box 
            my={4} 
            display="flex" 
            justifyContent="space-between" 
            alignItems="center">
            <Typography 
              variant="h4" 
              component="h1" 
              gutterBottom 
              align="center" 
              color="primary">
              Add Appointment
            </Typography>
          </Box>
          {
            stage === 'Services' &&
            <Box>
              <Stepper activeStep={activeStep} alternativeLabel>
                <Step key="Categories">
                  <StepLabel>Categories</StepLabel>
                </Step>
                <Step key="Services">
                  <StepLabel>Services</StepLabel>
                </Step>
                <Step key="SubServices">
                  <StepLabel>Sub Services</StepLabel>
                </Step>
                <Step key="MicroServices">
                  <StepLabel>Micro Services</StepLabel>
                </Step>
              </Stepper>
              <Box sx={{ p: 0, mb: 1, mt: 1 }}>
                {activeStep === 0 && (
                  <Categories onCategoryClick={handleCategoryClick} />
                )}
                {activeStep !== 0 && (
                  <Services
                    categoryId={selectedCategoryId}
                    activeStep={activeStep}
                    onServiceSelect={handleServiceClick} 
                  />
                )}
              </Box>
              <Box 
                display="flex" 
                justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    (activeStep === 0 && !selectedCategoryId) ||
                    (activeStep === 1 && !selectedService) ||
                    (activeStep === 2 && !selectedService) ||
                    (activeStep === 3 && !selectedService)
                  }
                >
                  {
                    activeStep === 3 ?
                    'Add' : 
                    'Next'
                  }
                </Button>
              </Box>
              {
                cartServices &&
                cartServices.length > 0 &&
                <Box
                  display="flex" 
                  justifyContent="space-between"
                  marginTop={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setStage('Checkout');
                    }}
                    style={{ width: '100%' }}
                  >
                    Go to Cart
                  </Button>
                </Box>
              }
            </Box>
          }
          {
            stage === 'Checkout' &&
            <Checkout 
              onCheckoutClick={() => {
                setStage('Calendar');
              }}
              onHandleBack={() => {
                setStage('Services')
              }}
            />
          }
          {
            stage === 'Calendar' &&
            <Calendar
              onSelectDateTime={handleSelectSlot}
              onHandleBack={() => {
                setStage('Checkout')
              }}
            />
          }
          {
            stage === 'Cart' &&
            <Cart 
              selectedDateTime={selectedDateTime} 
              onCartAccept={() => {
                setStage('Details');
              }}
              onHandleBack={() => {
                setStage('Calendar');
              }}
            />
          }
          {
            stage === 'Details' &&
            <Details 
              onConfirm={handleConfirm} 
              onHandleBack={() => {
                setStage('Cart');
              }}
            />
          }
        </Container>
      }
    </>
  )
};

export default AddAppointment;
