import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UserForm = ({ user, onUpdate }) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
        }));
    };

    const changePassword = () => {
        navigate('/change-password');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onUpdate(formData);
    };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          name="firstName"
          label="First Name"
          value={formData.firstName}
          onChange={handleChange}
          variant="outlined"
        />
        <TextField
          name="lastName"
          label="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          variant="outlined"
        />
        <TextField
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
        />
        <Button
          onClick={changePassword}
          variant="outlined"
          color="primary"
        >
          Change Password
        </Button>
        <Button 
            type="submit" 
            variant="contained" 
            color="primary">
            Update
        </Button>
      </Box>
    </form>
  );
};

export default UserForm;
