import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Container, TextField, Button, Box } from '@mui/material';
import { changePassword } from '../api/providers';
import { toastify } from '../utils';

const ChangePassword = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
           await changePassword(user.email, formData.oldPassword, formData.newPassword);
           toastify('Password updated', 'success');
           navigate('/my-profile');
        } catch (error) {
            console.error('Error changing password:', error);
            if (error.response.status === 403) {
                toastify('Current Password Incorrect', 'error')
            }
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom color="primary">
                Change Password
            </Typography>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { marginBottom: 2 },
                    '& .MuiButton-root': { marginTop: 2 }
                }}
                onSubmit={handleSubmit}
            >
                <TextField
                    fullWidth
                    name="oldPassword"
                    label="Current Password"
                    type="password"
                    value={formData.oldPassword}
                    onChange={handleChange}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    name="newPassword"
                    label="New Password"
                    type="password"
                    value={formData.newPassword}
                    onChange={handleChange}
                    variant="outlined"
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Box>
        </Container>
    );
};

export default ChangePassword;
