import axios from './axiosConfig';

const makePayment = async (data) => {
  try {
    const { data: { clientSecret } } = await axios.post('/create-payment-intent', {
        amount: data
    });
    return clientSecret;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;  // It is generally a good practice to throw the error to handle it later
  }
};

export { makePayment };
