import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import {
  Button,
  IconButton,
  Typography,
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import { getBlogs } from '../api/blogs';

const Blogs = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, [limit, offset, search, sorting]);

  const fetchBlogs = async () => {
    setLoading(true);
    const paginationData = {
      limit,
      offset,
      search,
      sort: sorting.length ? sorting[0] : {},
    };
    try {
      const response = await getBlogs(paginationData);
      setBlogs(response.blogs);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error('There was an error fetching blogs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = () => {
    setOffset(Math.max(0, offset - limit));
  };

  const handleNextPage = () => {
    if (offset + limit < totalCount) {
      setOffset(offset + limit);
    }
  };

  const columns = [
    { accessorKey: 'title', header: 'Title' },
    {
      accessorKey: 'date',
      header: 'Date',
      Cell: ({ row }) => new Date(row.original.date).toLocaleDateString(),
    },
    {
      accessorKey: 'published',
      header: 'Published',
      Cell: ({ row }) => row.original.published ? 'True' : 'False',
    },
  ];

  const numberOfPages = Math.ceil(totalCount / limit);
  const currentPage = Math.min(numberOfPages, Math.floor(offset / limit) + 1);

  return (
    <Container>
      <Box my={4}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          color="primary"
        >
          Blogs
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            sm={6}
            style={{ maxWidth: '300px', width: '100%' }}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => navigate('/blogs/add')}
            >
              Add Blog
            </Button>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <MaterialReactTable
            columns={columns}
            data={blogs}
            enableColumnOrdering
            initialState={{ density: 'compact' }}
            enablePagination={false}
            enableBottomToolbar={false}
            manualFiltering
            enableColumnFilters={false}
            enableColumnActions={false}
            onGlobalFilterChange={(value) => setSearch(value)}
            onSortingChange={setSorting}
            state={{ sorting }}
            manualSorting
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => navigate(`/blogs/${row.original._id}`),
              sx: {
                cursor: 'pointer',
              },
            })}
          />
        </>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={2}
      >
        <IconButton
          onClick={handlePreviousPage}
          disabled={offset <= 0}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Typography>
          Page {currentPage} of {numberOfPages}
        </Typography>
        <IconButton
          onClick={handleNextPage}
          disabled={offset + limit >= totalCount}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Container>
  );
};

export default Blogs;
