import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Container } from '@mui/material';
import UserForm from '../components/UserForm';
import { updateProvider } from '../api/providers';

const Profile = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const updateUser = async (updatedUser) => {
        try {
            await updateProvider(user.id, updatedUser);
            navigate('/apppointments');
        } catch (error) {
            console.error('Error updating provider:', error);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom color="primary">
                Profile
            </Typography>
            {
                user &&
                <UserForm 
                    user={user} 
                    onUpdate={updateUser} 
                />
            }
        </Container>
    );
};

export default Profile;
