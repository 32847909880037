import React, { useEffect, useState } from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { 
  getCategories,
} from '../api/categories';
import { 
  getServices,
} from '../api/services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AddServiceModal = ({ open, onClose, onAdd }) => {
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  const fetchCategoriesAndServices = async () => {
    const fetchedCategories = await getCategories();
    const fetchedServices = await getServices();
    setCategories(fetchedCategories);
    setServices(fetchedServices);
  };

  useEffect(() => {
    fetchCategoriesAndServices();
  }, []);

  const renderServices = (servicesList, level, parentId = null) => {
    return servicesList
      .filter(service => 
        (service.level === level && service.parentServiceId === parentId) || 
        (service.level === level && service.categoryId === parentId))
      .map(service => (
        <Accordion 
          key={service._id} 
          sx={{ 
            my: 1, 
            border: 1, 
            borderColor: 'divider' 
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: 'primary.light', 
            '.MuiAccordionSummary-expandIconWrapper': { color: 'primary.contrastText' }, 
            '.MuiTypography-root': { color: 'primary.contrastText' } 
          }}>
            <Typography>
              {service.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: 'column' }}>
            {service.level === 3 && renderServiceDetails(service)}
            {service.level < 3 && renderServices(services, service.level + 1, service._id)}
          </AccordionDetails>
        </Accordion>
      ));
  };

  const renderServiceDetails = (service) => {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 2,
            }}
          >
            <img
              alt={service.name}
              src={service.imageUrl}
              style={{ width: 200, height: 200 }}
            />
          </Box>
          <Typography paragraph>
            {service.description}
          </Typography>
          <Typography paragraph>
            {`Price: $${service.basePrice}`}
          </Typography>
          <Typography paragraph>
            {`Duration: ${service.baseDuration} hours`}
          </Typography>
          <Button
            variant={selectedService && selectedService._id === service._id ? 'contained': 'outlined'}
            color="primary"
            onClick={() => setSelectedService(service)}
          >
            {selectedService && selectedService._id === service._id ? 'Selected': 'Select'}
          </Button>
        </>
      )
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setSelectedService(null);
        onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box 
        sx={{
          position: 'absolute',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
      >
        <Typography 
          id="modal-title" 
          variant="h6" 
          component="h2"
          align="center" >
          Add New Service
        </Typography>
        <Typography 
          id="modal-description" 
          sx={{ mt: 2 }}>
        </Typography>
        <Box
          align="center"
          justifyContent="center"
          mb={3}
        >
          {
            categories.map(category => (
              <Accordion 
                key={category._id} 
                sx={{ 
                  my: 1, 
                  border: 1, 
                  borderColor: 'divider' 
                }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />} 
                  sx={{ 
                    backgroundColor: 'primary.main', 
                    '.MuiAccordionSummary-expandIconWrapper': { color: 'primary.contrastText' }, 
                    '.MuiTypography-root': { color: 'primary.contrastText' } 
                  }}>
                  <Typography>
                  {category.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                {renderServices(services, 1, category._id)}
                </AccordionDetails>
              </Accordion>
            ))
          }
        </Box>
        <Typography 
          variant="p" 
          component="p"
          align="center"
          mb={2}>
          {selectedService ?
          `Service Selected: ${selectedService.name}` :
          'Service Not Selected'}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-evenly" 
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedService === null}
            onClick={() => onAdd(selectedService)}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddServiceModal;
