import React from 'react';
import { gapi } from 'gapi-script';

const Calendar = () => {

  const calendarId = "sikandar@fixaroo.ca"

  const event = {
    'summary': 'Awesome Event',
    'location': '800 Howard St., San Francisco, CA 94103',
    'description': 'A great event to attend.',
    'start': {
      'dateTime': '2024-03-28T09:00:00',
      'timeZone': 'America/Toronto',
    },
    'end': {
      'dateTime': '2024-03-28T10:00:00',
      'timeZone': 'America/Toronto',
    },
    'reminders': {
      'useDefault': false,
      'overrides': [
        {'method': 'email', 'minutes': 24 * 60},
        {'method': 'popup', 'minutes': 10},
      ],
    },
  };

  const updatedEventDetails = {
    'summary': 'Awesome Event Updated',
    'location': 'New Location',
    'description': 'Updated description of the event.',
    'start': {
      'dateTime': '2024-03-29T09:00:00',
      'timeZone': 'America/Toronto',
    },
    'end': {
      'dateTime': '2024-03-29T10:00:00',
      'timeZone': 'America/Toronto',
    },
  };
  

  const addEvent = () => {
    try {
      const request = gapi.client.calendar.events.insert({
        'calendarId': calendarId,
        'resource': event
      });
      request.execute(event => {
        console.log('Event created: ', event);
      });
    } catch (error) {
      console.error('Error adding event: ', error);
    }
  };

  const getEvents = () => {
    try {
      gapi.client.calendar.events.list({
        'calendarId': calendarId,
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 2500,
        'orderBy': 'startTime'
      }).then(response => {
        const events = response.result.items;
        console.log("Events", events);
      });
    } catch (error) {
      console.error('Error retrieving events: ', error);
    }
  };

  const getEventById = (id) => {
    try {
      gapi.client.calendar.events.get({
        'calendarId': calendarId,
        'eventId': id,
      }).then(response => {
        const event = response.result;
        console.log("Event: ", event);
      });
    } catch (error) {
      console.error('Error retrieving event: ', error);
    }
  };

  const deleteEventById = (id) => {
    try {
      gapi.client.calendar.events.delete({
        'calendarId': calendarId,
        'eventId': id,
      }).then(response => {
        if (response.status === 204) {
          console.log("Event deleted successfully");
        } else {
          console.log("Unexpected response", response);
        }
      }, error => {
        console.error('Error deleting event: ', error);
      });
    } catch (error) {
      console.error('Exception when calling delete: ', error);
    }
  };

  const updateEventById = (id) => {
    try {
      gapi.client.calendar.events.update({
        'calendarId': calendarId,
        'eventId': id,
        'resource': updatedEventDetails
      }).then(response => {
        const updatedEvent = response.result;
        console.log("Updated Event: ", updatedEvent);
      }, error => {
        console.error('Error updating event: ', error);
      });
    } catch (error) {
      console.error('Exception when calling update: ', error);
    }
  };
  
  return (
    <>
      <div>
        Calendar  
      </div>
      <button onClick={addEvent}>
        add to calendar
      </button>
      <br/>
      <button onClick={getEvents}>
        get events
      </button>
      <br/>
      <button onClick={getEventById}>
        get event
      </button>
      <br/>
      <button onClick={deleteEventById}>
        delete event
      </button>
      <br/>
      <button onClick={updateEventById}>
        update event
      </button>
    </>
  )
};

export default Calendar;
