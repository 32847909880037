import React, { useState } from 'react';
import { 
  IconButton, 
  Typography, 
  Box, 
  Button, 
  TextField, 
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Details = ({ onConfirm, onHandleBack }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    postalCode: '',
    city: '',
    unitNumber: '',
  });

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files : value,
    }));
  };

  return (
    <>
      <IconButton color="primary" onClick={onHandleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" color="primary">
          User Details
        </Typography>
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          type="text"
          name="firstName"
          label="First Name"
          value={formData.firstName}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
        <TextField
          fullWidth
          type="text"
          name="lastName"
          label="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          type="tel"
          name="phone"
          label="Phone"
          value={formData.phone}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
        <TextField
          fullWidth
          type="email"
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          type="text"
          name="address"
          label="Address"
          value={formData.address}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
      </Box>
      <Box my={2}>
        <TextField
          type="text"
          name="postalCode"
          label="Postal Code"
          value={formData.postalCode}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
        <TextField
          type="text"
          name="city"
          label="City"
          value={formData.city}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
        <TextField
          type="text"
          name="unitNumber"
          label="Unit Number"
          value={formData.unitNumber}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
      </Box>
      <Box my={2}>
        <Button variant="contained" color="primary" onClick={() => onConfirm(formData)}>Confirm</Button>
      </Box>
    </>
  );
};

export default Details;
