import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { getCoupons, createCoupon, deleteCoupon, updateCouponToUsed } from '../api/coupons';
import { 
  Button, 
  IconButton, 
  Typography, 
  Box, 
  Container,
  Grid,
  Modal,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Tooltip
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 6 });

const Coupons = () => {
  const navigate = useNavigate();
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [open, setOpen] = useState(false);
  const [expiration, setExpiration] = useState(new Date());
  const [type, setType] = useState('percentage');
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    fetchCoupons();
  }, [limit, offset, search, sorting]);

  const fetchCoupons = async () => {
    const paginationData = {
      limit,
      offset,
      search,
      sort: sorting.length ? sorting[0] : {},
    };
    try {
      const response = await getCoupons(paginationData);
      setCoupons(response.coupons);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error('There was an error fetching coupons:', error);
    }
  };

  const handlePreviousPage = () => {
    setOffset(Math.max(0, offset - limit));
  };

  const handleNextPage = () => {
    if (offset + limit < totalCount) {
      setOffset(offset + limit);
    }
  };

  const handleCreateCoupon = async () => {
    const couponData = {
      code: uid.rnd(),
      expiration,
      type,
      amount,
      used: false
    };

    try {
      await createCoupon(couponData);
      setOpen(false);
      fetchCoupons();
    } catch (error) {
      console.error('There was an error creating the coupon:', error);
    }
  };

  const handleDeleteCoupon = async (id) => {
    try {
      await deleteCoupon(id);
      fetchCoupons();
    } catch (error) {
      console.error('There was an error deleting the coupon:', error);
    }
  };

  const handleUpdateCouponToUsed = async (id) => {
    try {
      await updateCouponToUsed({ id });
      fetchCoupons();
    } catch (error) {
      console.error('There was an error updating the coupon:', error);
    }
  };

  const columns = [
    { accessorKey: 'code', header: 'Code' },
    {
      accessorKey: 'expiration',
      header: 'Expiration',
      Cell: ({ row }) => new Date(row.original.expiration).toLocaleDateString(),
    },
    { accessorKey: 'type', header: 'Type' },
    { accessorKey: 'amount', header: 'Amount' },
    { accessorKey: 'used', header: 'Used', Cell: ({ row }) => (row.original.used ? 'Yes' : 'No') },
    {
      id: 'actions',
      header: 'Actions',
      Cell: ({ row }) => (
        !row.original.used && (
          <Box display="flex" justifyContent="left">
            <Tooltip title="Delete">
              <IconButton color="secondary" onClick={() => handleDeleteCoupon(row.original._id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mark as Used">
              <IconButton color="primary" onClick={() => handleUpdateCouponToUsed(row.original._id)}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      )
    }
  ];

  const numberOfPages = Math.ceil(totalCount / limit);
  const currentPage = Math.min(numberOfPages, Math.floor(offset / limit) + 1);

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
          Coupons
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} style={{ maxWidth: '300px', width: '100%' }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
            >
              Add Coupon
            </Button>
          </Grid>
        </Grid>
      </Box>
      {coupons && (
        <>
          <MaterialReactTable
            columns={columns}
            data={coupons}
            enableColumnOrdering
            initialState={{ density: 'compact' }}
            enablePagination={false}
            enableBottomToolbar={false}
            manualFiltering={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            onGlobalFilterChange={(value) => setSearch(value)}
            onSortingChange={setSorting}
            state={{ sorting }}
            manualSorting={true}
          />
        </>
      )}
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        marginTop={2}
      >
        <IconButton 
          onClick={handlePreviousPage} 
          disabled={offset <= 0}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Typography>
          Page {currentPage} of {numberOfPages}
        </Typography>
        <IconButton 
          onClick={handleNextPage} 
          disabled={offset + limit >= totalCount}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: 400, 
          bgcolor: 'background.paper', 
          border: '2px solid #000', 
          boxShadow: 24, 
          p: 4 
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Create Coupon
          </Typography>
          <Typography>
            Expiration
          </Typography>
          <DatePicker
            selected={expiration}
            onChange={(date) => setExpiration(date)}
            dateFormat="MM/dd/yyyy"
            customInput={<TextField fullWidth />}
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Type</FormLabel>
            <RadioGroup row value={type} onChange={(e) => setType(e.target.value)}>
              <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
              <FormControlLabel value="dollar" control={<Radio />} label="Dollar" />
            </RadioGroup>
          </FormControl>
          <TextField
            label="Amount"
            type="number"
            fullWidth
            margin="normal"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
          />
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={handleCreateCoupon}
            style={{ marginTop: '1rem' }}
          >
            Create
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default Coupons;
