import axios from './axiosConfig';
import { signInGoogleCalendar } from '../utils';

const loginUser = async (body) => {
  try {
    const response = await axios.post('/login', body);
    signInGoogleCalendar();
    return response;
  } catch (error) {
    console.error('There was an error!', error);
  }
};

const getUserById = async (userId) => {
  try {
    const response = await axios.get('/user', { params: { id: userId } });
    return response;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const verifyUser = async (token) => {
  try {
    const response = await axios.post('/auth/verifyUser', {}, {
      headers: { authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

export { loginUser, verifyUser, getUserById };
