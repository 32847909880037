import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { MaterialReactTable } from 'material-react-table';
import { getAppointments } from '../api/appointments';
import { 
  Button, 
  IconButton, 
  Typography, 
  Box, 
  Container,
  Grid
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import { formatDateTime } from '../utils';

const Appointments = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = useSelector((state) => state.auth.user);
  const providerId = queryParams.get('providerId');
  const navigate = useNavigate();
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setsearch] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    if (user && user.type === 'provider') {
      fetchAppointments(user.id);
    } else {
      fetchAppointments();
    }
    // eslint-disable-next-line
  }, [limit, offset, search, sorting, user]);

  const fetchAppointments = async (providerIdParam) => {
    const paginationData = {
      limit,
      offset,
      search,
      sort: sorting[0],
    };
    if (providerId) {
      paginationData.providerId = providerId;
    } else if (providerIdParam) {
      paginationData.providerId = providerIdParam;
    }
    try {
      const response = await getAppointments(paginationData);
      setAppointments(response.appointments);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error('There was an error fetching appointments:', error);
    }
  };

  const handlePreviousPage = () => {
    setOffset(Math.max(0, offset - limit));
  };

  const handleNextPage = () => {
    if (offset + limit < totalCount) {
      setOffset(offset + limit);
    }
  };

  const handleRowClick = (row) => {
    const { _id } = row;
    navigate(`/appointments/${_id}`);
  };

  const numberOfPages = Math.ceil(totalCount / limit);
  const currentPage = Math.min(numberOfPages, Math.floor(offset / limit) + 1);
  const columns = [
    { accessorKey: 'UUID', header: 'ID' },
    { accessorKey: 'address', header: 'Address' },
    {
      accessorKey: 'created',
      header: 'Created',
      Cell: ({ row }) => formatDateTime(row.original.created),
    },
    { accessorKey: 'status', header: 'Status' },
  ];

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
          Appointments
        </Typography>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={6} style={{ maxWidth: '300px', width: '100%' }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => navigate('/addAppointment')}
            >
              Add Appointment
            </Button>
          </Grid>
        </Grid>
      </Box>
      {appointments && (
          <>
            <MaterialReactTable
              columns={columns}
              data={appointments}
              enableColumnOrdering
              initialState={{ density: 'compact' }}
              enablePagination={false}
              enableBottomToolbar={false}
              manualFiltering={true}
              enableColumnFilters={false}
              enableColumnActions={false}
              onGlobalFilterChange={(value) => setsearch(value)}
              onSortingChange={setSorting}
              state={{ sorting }}
              manualSorting={true}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => {
                  handleRowClick(row.original);
                },
              })}
            />
          </>
      )}
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        marginTop={2}>
        <IconButton 
          onClick={handlePreviousPage} 
          disabled={offset <= 0}>
          <NavigateBeforeIcon />
        </IconButton>
        <Typography>
          Page {currentPage} of {numberOfPages}
        </Typography>
        <IconButton 
          onClick={handleNextPage} 
          disabled={offset + limit >= totalCount}>
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Container>
  );
};

export default Appointments;
