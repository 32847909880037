import React from 'react';
import { useSelector } from 'react-redux';
import { 
  Button, 
  Typography, 
  Box, 
  IconButton, 
  List, 
  ListItem, 
  ListItemText
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Checkout = ({ onCheckoutClick, onHandleBack }) => {
  const cartServices = useSelector((state) => state.cart.services);

  return (
    <Box mt={4}>
      <IconButton 
        color="primary" 
        onClick={() => onHandleBack()}>
        <ArrowBackIcon />
      </IconButton>
      {cartServices.length > 0 ? (
        <>
          <Typography 
            variant="h5" 
            gutterBottom
            color="primary">
            Cart
          </Typography>
          <Box ml={4}>
            <List>
              {cartServices.map((service, index) => (
                <ListItem key={index} disableGutters>
                  <ListItemText
                    primary={service.name}
                    secondary={`${service.basePrice ? `$${service.basePrice} - ` : ''}${service.baseDuration ? `${service.baseDuration} Hours` : ''}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onCheckoutClick()}
            fullWidth
          >
            Checkout
          </Button>
        </>
      ) : (
        <Typography variant="body1">Your cart is empty.</Typography>
      )}
    </Box>
  );
};

export default Checkout;
