export const addServiceToCart = (service) => {
  return {
    type: 'ADD',
    payload: service,
  };
};

export const deleteServiceFromCart = (service) => {
  return {
    type: 'DELETE',
    payload: service,
  };
};

export const clearCart = () => {
  return {
    type: 'CLEAR',
  };
};