import axios from './axiosConfig';

const getCalendar = async (from, to) => {
  try {
    const response = await axios.get('/calendar', {
      params: {
        from,
        to,
      },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const getCalendarDay = async (baseDurationInHours, slotDuration, appointmentDate) => {
  try {
    const response = await axios.get('/calendar/day', {
      params: {
        baseDurationInHours,
        slotDuration,
        appointmentDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

export { getCalendar, getCalendarDay };
