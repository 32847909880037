import React, { useState } from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  Button,
} from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { 
  checkIfDateTimeOverlapsForProvider
} from "../api/providers";
import { toastify } from "../utils";

const DateTimeModal = ({ 
  appointmentId, 
  date, 
  providerId, 
  duration, 
  open, 
  onClose, 
  handleAppointmentChange 
}) => {
  const [selectedDate, setSelectedDate] = useState(date);

  const handleSubmit = async () => {
    if (providerId) {
      const timeOverlaps = await checkIfDateTimeOverlapsForProvider(
        appointmentId,
        selectedDate, 
        duration, 
        providerId
      );
      if (timeOverlaps) {
        toastify('Handyman unavailable at this time', 'error');
      } else {
        handleAppointmentChange('dateTime', selectedDate);
        onClose();
      }
    } else {
      handleAppointmentChange("dateTime", selectedDate)
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box 
        sx={{
          position: 'absolute',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <Typography 
          id="modal-title" 
          variant="h6" 
          component="h2"
          align="center" >
          Edit Appointment Date
        </Typography>
        <Box
          align="center"
          justifyContent="center"
          mb={3}
          mt={3}
        >
          <DatePicker
            selected={new Date(selectedDate)}
            onChange={(value) =>
              setSelectedDate(value.toISOString())
            }
            showTimeSelect
            dateFormat="Pp"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-evenly" 
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DateTimeModal;
