import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { makePayment } from '../api/payments';
import { 
  getAppointment,
  updateAppointment,
 } from '../api/appointments';
import { getClient } from "../api/clients";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { 
  toastify,
} from "../utils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentInner = () => {
  const [appointment, setAppointment] = useState(null);
  const [client, setClient] = useState(null);
  const [amount, setAmount] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false); // New state to track payment success
  const { appointmentId: id } = useParams();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    fetchAppointment();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (appointment && appointment.clientId) {
      fetchClient(appointment.clientId);
    }
    // eslint-disable-next-line
  }, [appointment]);

  const fetchAppointment = async () => {
    try {
      const response = await getAppointment(id);
      setAppointment(response);
    } catch (error) {
      console.error("There was an error fetching appointment:", error);
    }
  };

  const fetchClient = async (clientId) => {
    try {
      const response = await getClient(clientId);
      setClient(response);
    } catch (error) {
      console.error("There was an error fetching client:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.log("Stripe has not loaded yet.");
      return;
    };
    const cardElement = elements.getElement(CardElement);
    const clientSecret = await makePayment(amount * 100);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: client.firstName + ' ' + client.lastName,
        },
      },
    });

    if (result.error) {
      toastify("Payment Failed!", "error");
    } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
      console.log('Payment successful: ', result.paymentIntent);
      const { id: paymentId } = result.paymentIntent;
      const appointmentObject = appointment;
      appointmentObject.balancePaid = parseInt(appointmentObject.balancePaid || 0) + parseInt(amount);
      if (appointmentObject.payments) {
        appointmentObject.payments.push(paymentId);
      } else {
        appointmentObject.payments = [paymentId];
      }
      await updateAppointment(appointmentObject, id);
      setPaymentSuccess(true);
    };
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        },
        padding: "10px 12px",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    },
    hidePostalCode: true
  };

  return (
    <div>
      {paymentSuccess ? (
        <Box textAlign="center">
          <Typography variant="h5" gutterBottom>
            Thank you for your payment!
          </Typography>
        </Box>
      ) : (
        <>
          {appointment && (
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Typography variant="body1" gutterBottom>
                Appointment UUID: {appointment.UUID}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Total Price: ${appointment.price}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Balance Paid: ${appointment.balancePaid || 0}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Balance Remaining: ${appointment.price - (appointment.balancePaid || 0)}
              </Typography>
            </div>
          )}
          <form 
            onSubmit={handleSubmit} 
            style={{
              maxWidth: '400px', 
              margin: '0 auto', 
              padding: '20px', 
              border: '1px solid #ccc', 
              borderRadius: '5px'
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              marginBottom="20px"
            >
              <TextField
                label="Amount"
                variant="outlined"
                type="number"
                fullWidth
                margin="normal"
                value={amount}
                onChange={handleAmountChange}
              />
            </Box>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <Button
              style={{
                marginTop: '10px'
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

const Payment = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentInner />
    </Elements>
  );
};

export default Payment;
