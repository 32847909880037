import axios from './axiosConfig';

const createCategory = async (categoryData) => {
  try {
    const response = await axios.post('/category', categoryData);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the category!', error);
    throw error;
  }
};

const getCategories = async () => {
  try {
    const response = await axios.get('/categories');
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const editCategory = async (id, categoryData) => {
  try {
    const response = await axios.put(`/category/${id}`, categoryData);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the category!', error);
    throw error;
  }
};

const deleteCategoryById = async (id) => {
  try {
    await axios.delete(`/category/${id}`);
  } catch (error) {
    console.error('There was an error deleting the category!', error);
    throw error;
  }
};

export { getCategories, editCategory, deleteCategoryById, createCategory };
