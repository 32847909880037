import axios from './axiosConfig';

const getBlogs = async (data) => {
  try {
    const response = await axios.get('/blogs', {
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const getBlogById = async (id) => {
  try {
    const response = await axios.get(`/blog/${id}`);
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const createBlog = async (blogData) => {
  try {
    const response = await axios.post('/blog', blogData);
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const updateBlogById = async (id, blogData) => {
  try {
    const response = await axios.put(`/blog/${id}`, blogData);
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const deleteBlogById = async (id) => {
  try {
    await axios.delete(`/blog/${id}`);
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

export {
  getBlogs,
  getBlogById,
  createBlog,
  updateBlogById,
  deleteBlogById,
};
