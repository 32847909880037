import { toast } from 'react-toastify';
import { gapi } from 'gapi-script';
import { storage } from './firebase';
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

const setToken = (token) => {
  localStorage.setItem('token', token);
};

const getToken = () => {
  return localStorage.getItem('token');
};

const deleteToken = () => {
  localStorage.removeItem('token');
};

const setGoogleToken = (token) => {
  localStorage.setItem('googleToken', token);
};

const getGoogleToken = () => {
  return localStorage.getItem('googleToken');
};

const deleteGoogleToken = () => {
  localStorage.removeItem('googleToken');
};

const getQueryParams = (query) => {
  return new URLSearchParams(query);
};

const stageLabel = (stage) => {
  switch (stage) {
    case '1':
      return 'Link Sent';
    case '2':
      return 'Quote Requested';
    case '3':
      return 'Quote Submitted';
    case '4':
      return 'Work in Progress';
    case '5':
      return 'Work Done';
    default:
      return 'Unknown Stage';
  }
};

const capitalizeFirstLetter = (word) => {
  if (!word) return word;
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

const toastify = (message, type) => {
  toast(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    type,
  });
};

const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };
  const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDateTime;
};

const addHoursToDate = (dateString, hoursToAdd) => {
  const date = new Date(dateString);
  date.setHours(date.getHours() + hoursToAdd);
  return date;
};

const addTaxToPrice = (price, tax) => {
  return (price * (1 + (tax/100))).toFixed(2)
};

const subtractTaxFromPrice = (totalPriceWithTax, taxPercentage) => {
  const originalPriceWithoutTax = totalPriceWithTax / (1 + taxPercentage / 100);
  return originalPriceWithoutTax.toFixed(2);
};

const addEventToCalendar = async (calendarId, event) => {
  return new Promise((resolve, reject) => {
    try {
      const request = gapi.client.calendar.events.insert({
        'calendarId': calendarId,
        'resource': event
      });

      request.execute(event => {
        if (event && event.id) {
          console.log('Event created: ', event);
          resolve(event.id);
        } else {
          reject('Event creation failed');
        }
      });
    } catch (error) {
      console.error('Error adding event: ', error);
    }
  });
};

const updateCalendarEventById = (calendarId, eventId, updatedEventDetails) => {
  try {
    gapi.client.calendar.events.update({
      'calendarId': calendarId,
      'eventId': eventId,
      'resource': updatedEventDetails
    }).then(response => {
      const updatedEvent = response.result;
      console.log("Updated Event: ", updatedEvent);
    }, error => {
      console.error('Error updating event: ', error);
    });
  } catch (error) {
    console.error('Exception when calling update: ', error);
  }
};

const deleteEventById = (calendarId, eventId) => {
  try {
    gapi.client.calendar.events.delete({
      'calendarId': calendarId,
      'eventId': eventId,
    }).then(response => {
      if (response.status === 204) {
        console.log("Event deleted successfully");
      } else {
        console.log("Unexpected response", response);
      }
    }, error => {
      console.error('Error deleting event: ', error);
    });
  } catch (error) {
    console.error('Exception when calling delete: ', error);
  }
};

const inistializeGoogleCalendarAPI = async () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; 
  const start = () => {
    gapi.client.init({
      clientId: clientId,
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
      scope: "https://www.googleapis.com/auth/calendar",
    }).then(() => {
      console.log("Google API client initialized.");
    });
  };
  gapi.load('client:auth2', start);
};

const signInGoogleCalendar = async () => {
  const GoogleAuth = gapi.auth2.getAuthInstance();
  let token;
  try {
    GoogleAuth.signIn()
    .then((res) => {
      token = res.xc.access_token;
      setGoogleToken(token);
      console.log("User signed into google calendar");
    })
    .catch(() => {
      console.log('errorrrr')
    });
  } catch (error) {
    console.log(error);
  }
};

const signOutGoogleCalendar = async () => {
  const GoogleAuth = gapi.auth2.getAuthInstance();
  GoogleAuth.signOut().then(() => {
    console.log("User signed out of google calendar");
  });
}

const uploadImage = async (file) => {
  const storageReference = storageRef(storage, `${file.name}`);
  console.log(storageReference)
  const snapshot = await uploadBytes(storageReference, file);
  console.log(snapshot)
  console.log(getDownloadURL(snapshot.ref))
  return getDownloadURL(snapshot.ref);
};

const dailyHoursDontHaveCapacity = (date, totalBaseDuration, calendar) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
  if (formattedDate in calendar) {
    const hoursOnDate = calendar[formattedDate];
    const capacityLeft = hoursOnDate - totalBaseDuration;
    if (capacityLeft < 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const convertTo12HourFormat = (time24) => {
  const [hours, minutes] = time24.split(':').map(num => parseInt(num, 10));
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  return `${hours12}:${minutes < 10 ? '0' + minutes : minutes}${period}`;
};

const getFormattedAddress = (formData) => {
  const { address, unitNumber, city, postalCode } = formData;
  return `${address}, ${city}, ${postalCode}${unitNumber ? `, Unit ${unitNumber}` : ''}`;
};

const hoursToHoursAndMins = (hours) => {
  const totalMinutes = hours * 60;
  const hoursPart = Math.floor(totalMinutes / 60);
  const minutesPart = totalMinutes % 60;
  return { hours: hoursPart, minutes: minutesPart };
};

export { 
  setToken,
  getToken,
  deleteToken,
  setGoogleToken, 
  getGoogleToken,
  deleteGoogleToken,
  getQueryParams,
  stageLabel,
  capitalizeFirstLetter,
  toastify,
  formatDateTime,
  addTaxToPrice,
  subtractTaxFromPrice,
  signInGoogleCalendar,
  signOutGoogleCalendar,
  inistializeGoogleCalendarAPI,
  uploadImage,
  addHoursToDate,
  addEventToCalendar,
  updateCalendarEventById,
  deleteEventById,
  dailyHoursDontHaveCapacity,
  convertTo12HourFormat,
  getFormattedAddress,
  hoursToHoursAndMins
}
  