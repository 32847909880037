import axios from './axiosConfig';

const getService = async (id) => {
  try {
    const response = await axios.get('/service', {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const getServices = async (level, parentServiceId, categoryId) => {
  try {
    const response = await axios.get('/services', {
      params: {
        level,
        parentServiceId,
        categoryId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

const editService = async (id, serviceData) => {
  try {
    const response = await axios.put(`/service/${id}`, serviceData);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the service!', error);
    throw error;
  }
};

const deleteServiceById = async (id) => {
  try {
    await axios.delete(`/service/${id}`);
  } catch (error) {
    console.error('There was an error deleting the service!', error);
    throw error;
  }
};

const createService = async (serviceData) => {
  try {
    const response = await axios.post('/service', serviceData);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the service!', error);
    throw error;
  }
};

export { getService, getServices, editService, deleteServiceById, createService };
