import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { deleteToken } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../redux/actions/authActions';
import { signOutGoogleCalendar } from '../utils';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const logoutFunction = () => {
    signOutGoogleCalendar();
    deleteToken();
    navigate('/login');
    dispatch(logout());
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  }

  return (
    <>
      <AppBar 
        position="static" 
        className="appBar">
        <Toolbar 
          className="toolbar" 
          sx={{ justifyContent: 'space-between' }}>
          <Box>
          {
            user &&
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          }
          </Box>
          <Typography 
            variant="h6" 
            className="title">
            Fixaroo Admin Panel
          </Typography>
          <Box>
            {
              user &&
              <Button 
                color="inherit" 
                onClick={logoutFunction}>
                  Logout
              </Button>
            }
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Box
          sx={{ 
            width: 250,
            height: '100%',
            backgroundColor: 'primary.main',
            paddingTop: 4
          }}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <Typography variant="h6" align="center" sx={{ color: 'white', marginBottom: 2 }}>
            Fixaroo Admin Panel
          </Typography>
          <List>
            <ListItem button onClick={() => navigate('/appointments')}>
              <ListItemText primary="Appointments" sx={{ textAlign: 'center', color: 'white' }} />
            </ListItem>
            {
              user &&
              user.type === 'admin' &&
              <>
                <ListItem button onClick={() => navigate('/summary')}>
                  <ListItemText primary="Summary" sx={{ textAlign: 'center', color: 'white' }} />
                </ListItem>
                <ListItem button onClick={() => navigate('/handymen')}>
                  <ListItemText primary="Handymen" sx={{ textAlign: 'center', color: 'white' }} />
                </ListItem>
                <ListItem button onClick={() => navigate('/services')}>
                  <ListItemText primary="Services" sx={{ textAlign: 'center', color: 'white' }} />
                </ListItem>
                <ListItem button onClick={() => navigate('/coupons')}>
                  <ListItemText primary="Coupons" sx={{ textAlign: 'center', color: 'white' }} />
                </ListItem>
                <ListItem button onClick={() => navigate('/blogs')}>
                  <ListItemText primary="Blogs" sx={{ textAlign: 'center', color: 'white' }} />
                </ListItem>
                <ListItem button onClick={() => navigate('/seo')}>
                  <ListItemText primary="SEO" sx={{ textAlign: 'center', color: 'white' }} />
                </ListItem>
              </>
            }
            <ListItem button onClick={() => navigate('/my-profile')}>
              <ListItemText primary="My Profile" sx={{ textAlign: 'center', color: 'white' }} />
            </ListItem>
          </List>
        </Box>
        <Box>
          {
            user &&
            <Button 
              color="inherit" 
              onClick={logoutFunction}>
                Logout
            </Button>
          }
        </Box>
      </Drawer>
    </>
  );
}

export default Header;
