import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Typography,
  Box,
  Container,
  CircularProgress,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { 
  getMetas, 
  createMeta,
  updateMeta, 
  deleteMeta,
} from '../api/seo';

const Metas = () => {
  const [loading, setLoading] = useState(false);
  const [metas, setMetas] = useState([]);
  const [editing, setEditing] = useState({});
  const [newMeta, setNewMeta] = useState({
    endpoint: '',
    title: '',
    description: '',
  });

  useEffect(() => {
    fetchMetas();
  }, []);

  const fetchMetas = async () => {
    setLoading(true);
    try {
      const response = await getMetas();
      setMetas(response);
    } catch (error) {
      console.error('There was an error fetching metas:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (id, field, value) => {
    setEditing((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleSave = async (id) => {
    try {
      await updateMeta(id, editing[id]);
      fetchMetas();
      setEditing((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    } catch (error) {
      console.error('There was an error updating the meta:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteMeta(id);
      fetchMetas();
    } catch (error) {
      console.error('There was an error deleting the meta:', error);
    }
  };

  const handleAddMetaChange = (field, value) => {
    setNewMeta((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddMetaSave = async () => {
    try {
      await createMeta(newMeta);
      fetchMetas();
      setNewMeta({
        endpoint: '',
        title: '',
        description: '',
      });
    } catch (error) {
      console.error('There was an error creating the meta:', error);
    }
  };

  return (
    <Container>
      <Box my={4}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          color="primary"
        >
          SEO
        </Typography>
      </Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Endpoint</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metas.map((meta) => (
                <TableRow key={meta._id}>
                  <TableCell>
                    <TextField
                      value={
                        editing[meta._id]?.endpoint ?? meta.endpoint
                      }
                      onChange={(e) =>
                        handleChange(meta._id, 'endpoint', e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={
                        editing[meta._id]?.title ?? meta.title
                      }
                      onChange={(e) =>
                        handleChange(meta._id, 'title', e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={
                        editing[meta._id]?.description ?? meta.description
                      }
                      onChange={(e) =>
                        handleChange(meta._id, 'description', e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleSave(meta._id)}
                      disabled={!editing[meta._id]}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(meta._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <TextField
                    value={newMeta.endpoint}
                    onChange={(e) =>
                      handleAddMetaChange('endpoint', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newMeta.title}
                    onChange={(e) =>
                      handleAddMetaChange('title', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newMeta.description}
                    onChange={(e) =>
                      handleAddMetaChange('description', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={handleAddMetaSave}
                    disabled={!newMeta.endpoint || !newMeta.title || !newMeta.description}
                  >
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default Metas;
