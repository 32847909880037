import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  Button, 
  Typography, 
  Box, 
  IconButton, 
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getCalendar, getCalendarDay } from '../api/calendar';
import { dailyHoursDontHaveCapacity, convertTo12HourFormat } from '../utils';

const Calendar = ({ onSelectDateTime, onHandleBack }) => {
  const cartServices = useSelector((state) => state.cart.services);
  const totalBaseDuration = useSelector((state) => state.cart.totalBaseDuration);
  const [calendar, setCalendar] = useState(null);
  const [timeSlots, setTimeSlots] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedTimeSlotIndex, setSelectedTimeSlotId] = useState(null);
  const timeSlotDuration = 0.5;

  const fetchCalendar = async () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const oneMonthLater = new Date();
    oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
    const calendar = await getCalendar(today, oneMonthLater);
    setCalendar(calendar);
  };

  const fetchCalendarDay = async (date) => {
    const slots = await getCalendarDay(totalBaseDuration, timeSlotDuration, date);
    setTimeSlots(slots);
  };

  useEffect(() => {
    fetchCalendar();
  }, []);

  const handleDateClick = async (date) => {
    setSelectedTimeSlot(null);
    setSelectedTimeSlotId(null);
    await fetchCalendarDay(date);
    setSelectedDate(date);
  }

  const handleTimeSlotClick = (slot, index) => {
    const { availability } = slot;
    if (availability) {
      setSelectedTimeSlot(slot);
      setSelectedTimeSlotId(index);
    }
  };

  const handleSelect = () => {
    const selectedDateTime = new Date(selectedDate);
    const [hours, minutes] = selectedTimeSlot.slot[0].split(':').map(Number);
    selectedDateTime.setHours(hours);
    selectedDateTime.setMinutes(minutes);
    onSelectDateTime(selectedDateTime);
  };
  
  return (
    <>
      <IconButton 
        color="primary" 
        onClick={() => onHandleBack()}>
        <ArrowBackIcon />
      </IconButton>
      {
        cartServices &&
        cartServices.length > 0 &&
        calendar && 
        <Box style={{ width: '600px' }}>
          <Typography 
            variant="h5" 
            gutterBottom
            color="primary">
            Calendar
          </Typography>
          <ReactCalendar 
            minDate={new Date()}
            tileDisabled={({ date }) => dailyHoursDontHaveCapacity(date, totalBaseDuration, calendar)}
            onClickDay={async (date) => await handleDateClick(date)}
          />
          {
            <Box mt={2}>
              {timeSlots && (
                <Box display="flex" flexDirection="column">
                  {timeSlots.map((timeSlot, i) => (
                    <Button
                      key={i}
                      onClick={() => handleTimeSlotClick(timeSlot, i)}
                      fullWidth
                      variant="outlined"
                      disabled={!timeSlot.availability}
                      sx={{
                        marginBottom: 1,
                        backgroundColor: selectedTimeSlotIndex === i ? 'primary.main' : 'inherit',
                        color: selectedTimeSlotIndex === i ? 'white' : 'inherit',
                        maxWidth: '400px',
                      }}
                    >
                      {convertTo12HourFormat(timeSlot.slot[0])} - {convertTo12HourFormat(timeSlot.slot[1])}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          }
        </Box>
      }
      {selectedTimeSlot && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSelect()}
          fullWidth
          sx={{ marginTop: 1 }}
        >
          Confirm Slot
        </Button>
      )}
    </>
  )
};

export default Calendar;
