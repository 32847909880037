import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { getProviders } from '../api/providers';
import { 
  Typography, 
  Button,
  Box, 
  Container 
} from '@mui/material';

const Handymen = () => {
  const navigate = useNavigate();
  const [handymen, setHandymen] = useState([]);

  useEffect(() => {
    fetchHandymen();
  }, []);

  const fetchHandymen = async () => {
    try {
      const response = await getProviders();
      setHandymen(response);
    } catch (error) {
      console.error('There was an error fetching handymen:', error);
    }
  };

  const handleHandymanAppointmentsClick = (id) => {
    navigate(`/appointments?providerId=${id}`);
  };

  const columns = [
    { accessorKey: 'firstName', header: 'First Name' }, 
    { accessorKey: 'lastName', header: 'Last Name' }, 
    { accessorKey: 'email', header: 'Email' }, 
    { accessorKey: 'phone', header: 'Phone' }, 
    { 
      accessorKey: 'actions', 
      header: 'Actions',
      Cell: ({ row }) => {
        const id = row.original._id;
        return (
          <Button
            onClick={() => handleHandymanAppointmentsClick(id)}
            variant='contained'>
            Appointments
          </Button>
        )
      }
    }, 
  ];

  return (
    <Container>
      <Box my={4}>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          align="center" 
          color="primary">
          Handymen
        </Typography>
      </Box>
      {handymen && (
          <>
            <MaterialReactTable
              columns={columns}
              data={handymen}
              enableColumnOrdering
              initialState={{ density: 'compact' }}
            />
          </>
      )}
    </Container>
  );
};

export default Handymen;
