import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';

const YourComponent = ({ notes }) => {
  return (
    <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Appointment Notes
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Additional Materials Needed: {notes.additionalMaterials ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        {notes.additionalMaterials && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Additional Materials Details: {notes.additionalMaterialsDetails || 'N/A'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Job Duration Exceeded: {notes.jobDurationExceeded ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        {notes.jobDurationExceeded && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Job Duration Exceed Reason: {notes.jobDurationExceedReason || 'N/A'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Issues Encountered: {notes.issuesEncountered ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        {notes.issuesEncountered && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Issues Description: {notes.issuesDescription || 'N/A'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Follow-Up Required: {notes.followUpRequired ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        {notes.followUpRequired && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Follow-Up Reasons: {notes.followUpReasons || 'N/A'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Recommendations: {notes.recommendations || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Payment Received: {notes.paymentReceived ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Customer Requests: {notes.customerRequests || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default YourComponent;
